export enum IActionsButtonType {
  SELECT = "select",
  CHECKBOX = "checkbox",
  ROTATE = "rotate",
  CHAT = "chat",
  BUTTON = "button",
  DATE = "date",
  EVENT = "event",
  FILTER = "filter"
}

export interface IActionsButton {
  name: string;
  range: string;
  defaultValue: string;
  type: IActionsButtonType;
  options: any[];
  users: IActionsButtonUser[];
  checkboxTrueValue: any;
  checkboxFalseValue: any;
  checkboxToggle: string;
  checkboxShowValue: boolean;
  componentPlugin: string;
  filterHideInput: boolean;
  parse(data: any): void;
  [key: string]: any;
}

export interface IActionsButtonUser {
  name: string;
  id: string;
}

export class Button implements IActionsButton {
  public name: string = "";
  public range: string = "";
  public defaultValue: string = "";
  public type: IActionsButtonType = IActionsButtonType.BUTTON;
  public options: string[] = [];
  public users: IActionsButtonUser[] = [];
  public checkboxTrueValue: any = "";
  public checkboxFalseValue: any = "";
  public checkboxToggle: string = "";
  public checkboxShowValue: boolean = false;
  public dateValueFormat: string = "YYYY-MM-DD";
  public dateDisplayFormat: string = "YYYY-MM-DD";
  public componentPlugin: string = "";
  public filterHideInput: boolean = false;
  constructor(data?: any) {
    this.parse(data || {});
  }
  public parse(data: any): void {
    [
      "type",
      "name",
      "defaultValue",
      "range",
      "options",
      "users",
      "checkboxTrueValue",
      "checkboxFalseValue",
      "checkboxToggle",
      "checkboxShowValue",
      "dateValueFormat",
      "dateDisplayFormat",
      "componentPlugin",
      "filterHideInput"
    ].forEach(key => {
      if (!data[key]) return;
      (<IActionsButton>this)[key] = data[key];
    });
  }
}
