import moment from "moment-timezone";
import Popper from "popper.js";
import { UAParser as ua } from "ua-parser-js";

import GridCanvas, { IGridCanvas, IGridImageOptions } from "./GridCanvas";
import { IPageMain, IPageColumnDefs } from "../Page/Page";
import {
  IGridCell,
  GridCell,
  IGridCellEvent,
  IGridCellBorderWidths,
} from "./GridCell";
import GridPage, {
  IGridPage,
  IGridPageContentCell,
  IGridPageContent,
} from "./GridPage";
import { IGridRow, GridRow } from "./GridRow";
import {
  IPageContent,
  IPageContentCell,
  IPageContentCellIndex,
} from "../Page/Content";
import { IActionsButton } from "../Actions/Buttons";
import GridEvents, {
  IGridEvents,
  IGridEventCellClicked,
  IGridEventCellSelected,
  IGridEventCellValue,
  IGridEventCellOption,
  IGridEventCellChecked,
} from "./GridEvents";
import { ITrackingData } from "../Tracking";
import Helpers, { IHelpers } from "../Helpers";
import { PermissionRange, utils } from "..";
import { IGridHeadingCell, GridHeadingCell } from "./GridHeadingCell";
import { IPageSchema, PageSchema } from "../Page/Schema";
import { IActionsStyleConditionStyle } from "../Actions/Styles";
import { GridFilterCell, IGridFilterCell } from "./GridFilterCell";
// import { IGridEvents } from '../GridEvents';

const helpers: IHelpers = new Helpers();

export interface IGridContentStyles extends Array<any> {
  [index: number]: IActionsStyleConditionStyle[];
}

export enum IGridFieldType {
  STRING = "string",
  NUMBER = "number",
  DATE = "date",
}
export enum IGridSortDirection {
  ASC = "asc",
  DESC = "desc",
}
export interface IGridSorting {
  enabled: boolean;
  field: string;
  direction: IGridSortDirection;
  col: number;
  type: IGridFieldType;
  dateFormat?: string;
  [key: string]: any;
}

export interface IGridFilter {
  col: number;
  field?: string;
  enabled?: boolean;
  exp: string;
  type: IGridFieldType;
  value: string;
  dateFormat?: string;
  name?: string;
}
export interface IGridState {
  filters: IGridFilter[];
  hiddenColumns: number[];
  sorting: IGridSorting;
}

export interface IGrid {
  columnFilters: boolean;
  structured: boolean;
  fit: string;
  contrast: string;
  freezeRow: number;
  freezeCol: number;
  minVisibleRows: number;
  highlights: boolean;
  tracking: boolean;
  disallowSelection: boolean;
  hoverHighlights: boolean;
  canEdit: boolean;
  gridlines: boolean;
  userId: number;
  height: number;
  width: number;
  content: IGridPageContent;

  destroy(): void;

  getContentHtml(type?: string): string;
  getSelectedCells(): IGridCells;

  setContent(content: IPageContent, state?: IGridState): void;
  setDeltaContent(content: any): void;
  setFreeze(row: number, col: number, headings?: boolean): void;
  setFit(fit: string): void;
  setContrast(which: string): void;
  setHiddenColumns(columns: number[]): void;

  update(refresh?: boolean): void;
  updateButtons(buttons?: IActionsButton[]): void;
  updateRanges(ranges: any, userId?: number): void;
  updateState(state: IGridState, internal?: boolean): void;
  updateColumnDefs(columnDefs: IPageColumnDefs[]): void;
  updateFreeze(row: number, col: number, headings?: boolean): void;
  updateFit(fit: string): void;
  updateTrackingData(trackingData: ITrackingData[]): void;
  updateStyles(styles?: IGridContentStyles): void;
  updateColWidth(colIndex: number, cellSize: number): void;

  // tracking
  updateTrackingData(trackingData: ITrackingData[]): void;
  clearTrackingData(): void;
  setSelectorByRef(str: string): void;
  setSelectorByIndex(
    from: IPageContentCellIndex,
    to?: IPageContentCellIndex
  ): void;
  clearColumnFilters(): void;

  image(options?: IGridImageOptions): string;
  createSchema(columnKeys: number[]): IPageSchema;

  [key: string]: any;
}

export interface IGridOptions {
  fit?: string;
  headings?: boolean;
  gridlines?: boolean;
  highlights?: boolean;
  tracking?: boolean;
  contrast?: string;
  disallowSelection?: boolean;
  canEdit?: boolean;
  [key: string]: any;
}

export interface IGridSelection {
  from?: IGridCell;
  to?: IGridCell;
  last?: IGridCell;
  selected: boolean;
  rowFrom: number;
  rowTo: number;
  colFrom: number;
  colTo: number;
  heading: boolean;
  inside: boolean;
  // rowStart: number;
  // rowEnd: number;
  // rowLast: number;
  // colLast: number;
  // reference: string;
  keyboard: boolean;
  type: string;
  [key: string]: any;
}

interface IGridButtonIndexes extends Array<any> {
  [index: number]: IActionsButton[];
}

export interface IGridCells extends Array<any> {
  [index: number]: IGridCell[];
}

export class Grid implements IGrid {
  // public structured: boolean;
  public contrast: string = "";
  public freezeRow: number = 0;
  public freezeRowTop: number = 0;
  public freezeColLeft: number = 0;
  public freezeCol: number = 0;
  public minVisibleRows: number = 100;
  public highlights: boolean = false;
  public isFocus: boolean = false;
  public disallowSelection: boolean = false;
  public canEdit: boolean = true;
  public userId: number = 0;
  public height: number = 0;
  public width: number = 0;
  public content: IGridPageContent = [];
  public rowsToHide: number[] = [];

  public state: IGridState = {
    filters: [],
    hiddenColumns: [],
    sorting: {
      col: -1,
      field: "",
      direction: IGridSortDirection.ASC,
      enabled: false,
      type: IGridFieldType.STRING,
    },
  };
  public Events: IGridEvents;
  public Page: IGridPage;
  public Canvas!: IGridCanvas;

  private initialized: boolean = false;
  private rendering: boolean = false;
  private scrollTop: number = 0;
  private visibleRows: number[] = [];
  // private hiddenColumns: number[] = [];
  // private scrollbarWidth = 0;
  private _fit: string = "scroll";
  private _editing: boolean = false;
  private _hasFocus: boolean = false;
  private _gridlines: boolean = false;
  private _headings: boolean = false;
  private _hoverHighlights: boolean = false;
  private _underlineLinks: boolean = true;
  private doubleClicked: boolean = false;
  private clickedAt: number = 0;
  private historyClick: boolean = false;
  private filterClick: boolean = false;
  private rightClick: boolean = false;
  private _optimisedScrolling: boolean = false;
  private uuid: any = "";
  private forceUpdate: boolean = false;
  private dirty: boolean = false;
  private rowChecked: boolean = false;
  private scale: number = 0;
  private pinchDistance: number = 0;
  private pinchScale: number = 0;

  // tracking
  public tracking: boolean = false;
  public trackingData: ITrackingData[] | undefined;
  private trackingUsers: number[] = [];

  // dom
  public popperParent!: HTMLDivElement;
  private pageEl!: HTMLDivElement;
  private editEl!: HTMLDivElement;
  private pageElRect!: ClientRect;
  private scaleEl!: HTMLDivElement;
  private mainEl!: HTMLDivElement;
  private freezeRowEl!: HTMLDivElement;
  private freezeCornerEl!: HTMLDivElement;
  private freezeColEl!: HTMLDivElement;
  private headingRow!: HTMLDivElement;
  private scrollEl!: HTMLDivElement;
  private noMatchesEl!: HTMLDivElement;
  private containerEl!: HTMLDivElement;
  private boxEl!: HTMLDivElement;
  private boxColEl!: HTMLDivElement;
  private boxRowEl!: HTMLDivElement;
  private selectorWrapperEl!: HTMLDivElement;
  private selectorEl!: HTMLDivElement;
  private columnsEl!: HTMLDivElement;
  private columnSplitEl!: HTMLDivElement;
  private selectorBoxEl!: HTMLDivElement;
  private rowFragments: DocumentFragment[] = [];
  private styleSheet!: HTMLStyleElement;
  private rowStyle!: Text;
  private colStyles: Text[] = [];
  private sortStyles!: Text;
  private input!: HTMLInputElement;
  private sortWrapperEl!: HTMLDivElement;
  private sortEl!: HTMLDivElement;

  // cells
  private gridCells: IGridCells = [[]];
  private gridFilterCells: IGridFilterCell[] = [];
  private gridCornerHeading!: IGridRow;
  private gridRowHeading!: IGridRow;
  private gridRowFilters!: IGridRow;
  private gridCornerRowFilters!: IGridRow;
  private gridCornerHeadingCell!: IGridHeadingCell;
  private gridRowHeadingCells: IGridHeadingCell[] = [];
  private gridColumnHeadingCells: IGridHeadingCell[] = [];
  private rows: IGridRow[] = [];
  private colRows: IGridRow[] = [];
  private cornerRows: IGridRow[] = [];
  private rowRemovalInterval: any;
  private selection: IGridSelection = {
    rowFrom: -1,
    rowTo: -1,
    colFrom: -1,
    colTo: -1,
    heading: false,
    inside: false,
    selected: false,
    keyboard: false,
    type: "cell",
  };
  private buttons: IGridButtonIndexes = [];
  private styles: IGridContentStyles = [];
  private accessRanges: any = [];
  private found: IPageContent = [];

  // keyboard tracking
  private ctrlDown: boolean = false;
  private shiftDown: boolean = false;
  private arrowKeyDown: boolean = false;
  private keyValue!: string | undefined;
  private hammer!: HammerManager;
  private editingCell: IGridCell | undefined;
  private buttonPopper: any;

  // editing cell
  private editValue: any;
  private editDone: boolean = false;

  // column sorting tracking
  private sortColLeft: number = 0;
  private sortColWidth: number = 0;
  private sortColScroll: boolean = false;
  private sortColMoveTo: number = -1;

  constructor(public ref: string | HTMLDivElement, options?: IGridOptions) {
    const pageEl =
      typeof this.ref === "string"
        ? (document.getElementById(this.ref) as HTMLDivElement)
        : this.ref;
    if (!pageEl) throw new Error("Page element not found");
    this.pageEl = pageEl;
    this.Events = new GridEvents();
    this.Page = new GridPage();

    let parser = new ua();
    let parseResult = parser.getResult();
    this.Page.touch =
      parseResult.device &&
      (parseResult.device.type === "tablet" ||
        parseResult.device.type === "mobile");
    if (this.Page.touch) this.pageEl.classList.add("touch");

    if (options) {
      Object.keys(options).forEach((key: string) => {
        (<IGrid>this)[key] = options[key];
      });
    }

    this.Page.uuid = `${Math.round(Math.random() * new Date().getTime())}`;
    this.pageEl.classList.add(`grid-${this.Page.uuid}`);
  }

  public image(options: IGridImageOptions): string {
    this.Canvas.gridCells = this.gridCells;
    this.Canvas.gridWidth = this.width;
    this.Canvas.gridHeight = this.height;
    return this.Canvas.render(options);
  }

  public get structured(): boolean {
    return this.Page.structured;
  }

  public set structured(n: boolean) {
    this.Page.structured = n;
    if (n) {
      this.pageEl.classList.add("structured");
    } else {
      this.pageEl.classList.remove("structured");
    }
  }

  public get columnFilters(): boolean {
    return this.Page.structured;
  }

  public set columnFilters(n: boolean) {
    this.Page.columnFilters = n;
    if (n) {
      this.pageEl.classList.add("filters");
    } else {
      this.pageEl.classList.remove("filters");
    }
  }

  public get hoverHighlights(): boolean {
    return this._hoverHighlights;
  }

  public set hoverHighlights(n: boolean) {
    this._hoverHighlights = n;
    if (n) {
      this.pageEl.classList.add("hover");
    } else {
      this.pageEl.classList.remove("hover");
    }
  }

  public get underlineLinks(): boolean {
    return this._underlineLinks;
  }

  public set underlineLinks(n: boolean) {
    this._underlineLinks = n;
    if (n) {
      this.pageEl.classList.remove("hide-link-underlines");
    } else {
      this.pageEl.classList.add("hide-link-underlines");
    }
  }

  public get gridlines(): boolean {
    return this._gridlines;
  }

  public set gridlines(n) {
    this._gridlines = n;
    if (this.gridlines) {
      this.pageEl.classList.add("gridlines");
    } else {
      this.pageEl.classList.remove("gridlines");
    }
  }

  public get headings(): boolean {
    return this._headings;
  }

  public set headings(n) {
    this._headings = n;
    this.Page.headings = n;
    if (this.headings) {
      this.pageEl.classList.add("headings");
    } else {
      this.pageEl.classList.remove("headings");
      if (this.mainEl) this.mainEl.style.removeProperty("top");
    }
  }

  public get hasFocus(): boolean {
    return this._hasFocus;
  }

  public set hasFocus(n: boolean) {
    this._hasFocus = n;
    if (n) {
      this.pageEl.classList.add("focus");
    } else {
      this.pageEl.classList.remove("focus");
    }
  }

  public get fit(): string {
    return this._fit;
  }

  public set fit(n: string) {
    this._fit = ["scroll", "width", "height", "contain"].includes(n)
      ? n
      : "scroll";
  }

  public get softMerges(): boolean {
    return this.Page.softMerges;
  }

  public set softMerges(n: boolean) {
    this.Page.softMerges = n;
  }

  private get editing(): boolean {
    return this._editing;
  }

  private set editing(n: boolean) {
    if (!this.canEdit) return;
    this._editing = n;
    if (this.dirty) {
      this.Events.emit(this.Events.EDITING, {
        cell: this.selection.from,
        editing: this._editing,
        dirty: this.dirty,
      });
      this.dirty = false;
    }
    if (n) {
      this.pageEl.classList.add("editing");
    } else {
      this.pageEl.classList.remove("editing");
      this.keyValue = "";
      this.destroyPopper();
    }
  }

  public set optimiseScrolling(n: boolean) {
    this._optimisedScrolling = n;
    if (n) {
      this.pageEl.classList.add("optimised");
    } else {
      this.pageEl.classList.remove("optimised");
    }
  }

  public destroy(): void {
    if (!this.initialized) return;
    this.destroyListeners();
    this.reset();
  }

  public setContent(
    content: IGridPageContent,
    state?: IGridState,
    columnDefs?: IPageColumnDefs[]
  ): void {
    this.init();
    this.state = state || this.getDefaultState();
    this.Page.reset();
    this.Page.content = this.Page.originalContent = content;
    this.Page.columnsDefs = columnDefs || [];

    // column styles
    for (
      let colIndex = 0;
      colIndex < this.Page.originalContent[0].length;
      colIndex++
    ) {
      this.colStyles[colIndex] = document.createTextNode("");
      this.styleSheet.appendChild(this.colStyles[colIndex]);
    }

    // corner
    this.gridCornerHeading = new GridRow(this.Page, this.Events, 0, true);
    this.gridCornerHeading.setIndex(0);
    this.freezeCornerEl.appendChild(this.gridCornerHeading.rowEl);

    // corner cell
    this.gridCornerHeadingCell = new GridHeadingCell(
      this.Page,
      this.Events,
      0,
      0,
      "corner"
    );
    this.gridCornerHeading.rowEl.appendChild(this.gridCornerHeadingCell.cell);

    // heading row
    this.gridRowHeading = new GridRow(this.Page, this.Events, 0, true);
    this.gridRowHeading.setIndex(0);
    this.freezeRowEl.appendChild(this.gridRowHeading.rowEl);

    // filter row
    this.gridRowFilters = new GridRow(this.Page, this.Events, 0, false, true);
    this.gridRowFilters.setIndex(0);
    this.freezeRowEl.appendChild(this.gridRowFilters.rowEl);

    // filter corner row
    this.gridCornerRowFilters = new GridRow(
      this.Page,
      this.Events,
      0,
      false,
      true
    );
    this.gridRowFilters.setIndex(0);
    this.freezeCornerEl.appendChild(this.gridCornerRowFilters.rowEl);

    // column heading cells
    for (
      let colIndex = 0;
      colIndex < this.Page.originalContent[0].length;
      colIndex++
    ) {
      // filter cells
      this.gridFilterCells[colIndex] = new GridFilterCell(
        this.Page,
        this.Events,
        0,
        colIndex,
        "col"
      );
      this.gridRowFilters.rowEl.appendChild(
        this.gridFilterCells[colIndex].cell
      );
      // heading cells
      this.gridColumnHeadingCells[colIndex] = new GridHeadingCell(
        this.Page,
        this.Events,
        0,
        colIndex,
        "col"
      );
      this.gridRowHeading.rowEl.appendChild(
        this.gridColumnHeadingCells[colIndex].cell
      );
    }
    this.applyFilterValues();
    this.initialized = true;
  }
  public setDeltaContent(content: IPageContent): void {
    if (!content || !content.length) {
      return;
    }

    // update content
    for (let rowIndex = 0; rowIndex < content.length; rowIndex++) {
      let row = content[rowIndex];
      if (!row) continue;
      for (let colIndex = 0; colIndex < row.length; colIndex++) {
        if (!row[colIndex]) continue;
        this.Page.originalContent[rowIndex][colIndex] = row[
          colIndex
        ] as IGridPageContentCell;
      }
    }

    // update grid cell
    this.Page.deltaContent = JSON.parse(JSON.stringify(content));
    // let gridCells: IGridCell[] = [];
    for (let rowIndex = 0; rowIndex < content.length; rowIndex++) {
      let row = content[rowIndex];
      if (!row) continue;
      for (let colIndex = 0; colIndex < row.length; colIndex++) {
        // if (!row[colIndex]) continue;
        let gridCell = this.getGridCell(rowIndex, colIndex);
        if (gridCell) {
          gridCell.update(true);
          if (this.highlights) gridCell.highlight();
          // gridCells.push(gridCell);
          // gridCell.cellText.textContent = `${row[colIndex].value}`;
        }
      }
    }

    this.applyState(true);

    // highlight changes
    // if (this.highlights) {
    //   gridCells.forEach((gridCell) => {
    //     gridCell.highlight();
    //   });
    // }
    // this.forceUpdate = true;
    this.render();
    // requestAnimationFrame(this.render);
  }
  public setFreeze(row: number, col: number): void {
    this.freezeRow = row;
    // this.freezeRowTop = this.Page.rowPos(row);
    this.freezeCol = col;
    // this.gridRowFilters.setIndex(row);
    // this.freezeColLeft = this.Page.colPos(col);
  }
  public setFit(fit: string): void {
    this.fit = fit;
  }
  public setContrast(which: string): void {
    this.contrast = which;
    this.pageEl.classList.remove("light", "dark");
    this.pageEl.classList.add(this.contrast);
  }
  public setHiddenColumns(columns: number[]): void {
    this.Page.hiddenColumns = columns;
    this.Page.hiddenColumns.sort();
  }

  public update(refresh?: boolean): void {
    this.applyState(refresh);
    this.applyScale();
    this.updateSelector();

    if (!this.Page.content.length && !this.Page.columnFilters) {
      this.containerEl.style.setProperty("display", "none");
      this.noMatchesEl.style.setProperty("display", "block");
      return;
    } else {
      this.containerEl.style.removeProperty("display");
    }
    this.forceUpdate = true;
    requestAnimationFrame(this.render);
    this.applySortIndicator();
    // this.applyMergeWidths();

    // if (this.rowRemovalInterval) {
    //   clearInterval(this.rowRemovalInterval);
    //   this.rowRemovalInterval = null;
    // }
    // this.rowRemovalInterval = setInterval(() => {
    //   for (let rowIndex = this.visibleRows.length; rowIndex < this.rows.length; rowIndex++) {
    //     // if (this.visibleRows.includes(this.rows[rowIndex].row)) continue;
    //     this.rows[rowIndex].rowEl.style.setProperty('display', 'none');
    //   }
    // }, 300);
  }
  public updateState(state: IGridState, internal?: boolean): void {
    this.state = state;
    if (!internal) this.applyFilterValues();
    if (!this.initialized) return;
    this.forceUpdate = true;
    this.clearSelector();
    this.update();
    // this.applyState();
    // requestAnimationFrame(this.render);
  }
  public updateColumnDefs(columnDefs: IPageColumnDefs[]): void {
    this.Page.columnsDefs = columnDefs;
    this.forceUpdate = true;
    this.clearSelector();
    this.update();
  }
  public updateHeadings(headings: boolean): void {
    this.headings = headings;
    this.forceUpdate = true;
    this.clearSelector();
    this.update();
  }
  public updateColumnFilters(n: boolean): void {
    this.columnFilters = n;
    this.forceUpdate = true;
    this.clearSelector();
    this.update();
  }
  public updateFreeze(row: number, col: number): void {
    this.mainEl.scrollTop = 0;
    this.mainEl.scrollLeft = 0;
    this.setFreeze(row, col);
    this.clearSelector();
    this.update();
    // requestAnimationFrame(this.render);
  }
  public updateFit(fit: string): void {
    this.fit = fit;
    // this.applyScale();
    this.update();
    this.updateElSizes();
  }
  public updateButtons(buttons?: IActionsButton[]): void {
    // clear any current buttons
    if (this.buttons) {
      for (let rowIndex = 0; rowIndex < this.buttons.length; rowIndex++) {
        let row = this.buttons[rowIndex];
        if (!row) continue;
        for (let colIndex = 0; colIndex < row.length; colIndex++) {
          let button = row[colIndex];
          if (!button) continue;
          this.applyCellUpdate(rowIndex, colIndex, "button", null);
        }
      }
    }
    this.buttons = [];
    if (!buttons) {
      return;
    }
    buttons.forEach((button) => {
      let range = helpers.cellRange(
        button.range,
        this.Page.content.length,
        this.Page.content[0].length
      );
      if (range.to.row < 0) range.to.row = this.Page.content.length;
      if (range.to.col < 0) range.to.col = this.Page.content[0].length;
      for (
        let rowIndex = range.from.row;
        rowIndex <= range.to.row;
        rowIndex++
      ) {
        for (
          let colIndex = range.from.col;
          colIndex <= range.to.col;
          colIndex++
        ) {
          if (!this.buttons[rowIndex]) this.buttons[rowIndex] = [];
          this.buttons[rowIndex][colIndex] = button;
          this.applyCellUpdate(rowIndex, colIndex, "button", button);
        }
      }
    });
  }

  public updateStyles(styles?: IGridContentStyles): void {
    // clear any styles
    if (this.styles) {
      for (let rowIndex = 0; rowIndex < this.styles.length; rowIndex++) {
        let row = this.styles[rowIndex];
        if (!row) continue;
        for (let colIndex = 0; colIndex < row.length; colIndex++) {
          let style = row[colIndex];
          if (!style) continue;
          this.applyCellUpdate(rowIndex, colIndex, "style", null);
        }
      }
    }
    this.styles = [];
    if (!styles || !styles.length) {
      return;
    }
    this.styles = styles;
    this.applyStyles();
  }

  public updateColWidth(colIndex: number, cellSize: number): void {
    this.Page.colWidths[colIndex] = cellSize;
    this.Page.colWidths.forEach((width, colIndex) => {
      this.applyColWidth(colIndex, width);
    });
  }

  public updateRanges(ranges: any, userId: number = 0): void {
    if (this.accessRanges) {
      for (let rowIndex = 0; rowIndex < this.accessRanges.length; rowIndex++) {
        let row = this.accessRanges[rowIndex];
        if (!row) continue;
        for (let colIndex = 0; colIndex < row.length; colIndex++) {
          let gridCell = this.getGridCell(rowIndex, colIndex, true);
          if (!gridCell) continue;
          gridCell.setPermission("rw");
        }
      }
    }
    this.accessRanges = [];
    if (!ranges || !ranges.length) return;
    // let offset = this.Grid.Settings.headings ? 1 : 0;
    let rowsToUpdate: number[] = [];
    for (let i: number = 0; i < ranges.length; i++) {
      let range: any = ranges[i];

      let rowEnd: number;
      let colEnd: number;
      if (range.range) {
        rowEnd = range.range.to.row;
        colEnd = range.range.to.col;
        if (rowEnd === -1) {
          rowEnd = this.Page.content.length - 1;
        }
        if (colEnd === -1) {
          colEnd = this.Page.content[0].length - 1;
        }
      }

      if (range instanceof PermissionRange && this.Page.content.length) {
        let userRight: any =
          range.getPermission(userId || 0) || (userId ? "rw" : "no");
        let rowEnd: number = range.rowEnd;
        let colEnd: number = range.colEnd;
        if (rowEnd === -1) {
          rowEnd = this.Page.content.length - 1;
        }
        if (colEnd === -1) {
          colEnd = this.Page.content[0].length - 1;
        }
        for (let i: number = range.rowStart; i <= rowEnd; i++) {
          rowsToUpdate.push(i);
          for (let k: number = range.colStart; k <= colEnd; k++) {
            if (!this.accessRanges[i]) {
              this.accessRanges[i] = [];
            }
            if (!this.accessRanges[i][k]) {
              this.accessRanges[i][k] = [];
            }
            if (userRight) {
              this.accessRanges[i][k] = userRight;
              this.applyCellUpdate(i, k, "permission", userRight);
            }
          }
        }
      }
    }
    if (rowsToUpdate.length) {
      // this.updateSoftMerges(rowsToUpdate);
    }
  }

  public getContentHtml(type?: string): string {
    let only = type === "symphony" ? helpers.getSymphonyStyles() : [];
    let html = `<table style="border-collapse: collapse;">`;
    for (let rowIndex = 0; rowIndex < this.content.length; rowIndex++) {
      let row = this.content[rowIndex];
      if (this.Page.hiddenRows.indexOf(row[0].index.row) > -1) continue;
      if (this.selection.rowFrom > -1 && this.selection.rowTo > -1) {
        if (
          rowIndex < this.selection.rowFrom ||
          rowIndex > this.selection.rowTo
        )
          continue;
      }
      html += `<tr>`;
      for (let colIndex = 0; colIndex < row.length; colIndex++) {
        if (this.state.hiddenColumns.indexOf(colIndex) > -1) continue;
        if (this.selection.colFrom > -1 && this.selection.colTo > -1) {
          if (
            colIndex < this.selection.colFrom ||
            colIndex > this.selection.colTo
          )
            continue;
        }
        let cell: any = row[colIndex];
        let gridCell = this.getGridCell(cell.index.row, colIndex);
        let styles = gridCell ? gridCell.getFlattenStyles(only) : "";
        html += `<td style="${styles}"><div>${
          cell.formatted_value || cell.value || "&nbsp;"
        }</div></td>`;
      }
      html += `</tr>`;
    }
    html += `</table>`;
    return html;
  }

  public find(query: string): void {
    this.clearFound();

    for (let rowIndex = 0; rowIndex < this.content.length; rowIndex++) {
      let row = this.content[rowIndex];
      for (let colIndex = 0; colIndex < row.length; colIndex++) {
        let col = row[colIndex];
        let regex = new RegExp(query, "ig");
        if (
          !regex.test(`${col.value}`) &&
          !regex.test(`${col.formatted_value}`)
        ) {
          continue;
        }
        if (!this.found[rowIndex]) {
          this.found[rowIndex] = [];
        }
        this.found[col.index.row][colIndex] = col;
        this.applyCellUpdate(col.index.row, col.index.col, "found", true);
      }
    }
  }

  public clearFound(): void {
    if (this.found && this.found.length) {
      for (let rowIndex = 0; rowIndex < this.found.length; rowIndex++) {
        let row = this.found[rowIndex];
        if (!row) continue;
        for (let colIndex = 0; colIndex < row.length; colIndex++) {
          let found = row[colIndex];
          if (!found) continue;
          this.applyCellUpdate(rowIndex, colIndex, "found", null);
        }
      }
    }
    this.found = [];
  }

  public createSchema(columnKeys: number[]): IPageSchema {
    let pageSchema = new PageSchema();
    let content = this.Page.content;
    pageSchema.importContent(content, columnKeys);
    return pageSchema;
  }

  private applyStyles(): void {
    for (let rowIndex = 0; rowIndex < this.styles.length; rowIndex++) {
      let row = this.styles[rowIndex];
      if (!row) continue;
      for (let colIndex = 0; colIndex < row.length; colIndex++) {
        let style = this.styles[rowIndex][colIndex];
        if (!style) continue;
        this.styles[rowIndex][colIndex] = style;
        this.applyCellUpdate(rowIndex, colIndex, "style", style);
      }
    }
  }

  private applyButtons(): void {
    for (let rowIndex = 0; rowIndex < this.buttons.length; rowIndex++) {
      let row = this.buttons[rowIndex];
      if (!row) continue;
      for (let colIndex = 0; colIndex < row.length; colIndex++) {
        let style = this.buttons[rowIndex][colIndex];
        if (!style) continue;
        this.buttons[rowIndex][colIndex] = style;
        this.applyCellUpdate(rowIndex, colIndex, "button", style);
      }
    }
  }

  private applyRanges(): void {
    for (let rowIndex = 0; rowIndex < this.accessRanges.length; rowIndex++) {
      let row = this.accessRanges[rowIndex];
      if (!row) continue;
      for (let colIndex = 0; colIndex < row.length; row++) {
        let userRight = this.accessRanges[rowIndex][colIndex];
        if (!userRight) continue;
        this.applyCellUpdate(rowIndex, colIndex, "permission", userRight);
      }
    }
  }

  private applyCellUpdate(
    rowIndex: number,
    colIndex: number,
    which: string,
    value: any
  ): void {
    let gridCell = this.getGridCell(rowIndex, colIndex);
    if (!gridCell) return;
    // if (!gridCell.init) return;
    if (which === "sorting") gridCell.setSorting(value);
    if (which === "permission") gridCell.setPermission(value);
    if (which === "style") gridCell.setStyle(value);
    if (which === "button") gridCell.setButton(value);
    if (which === "found") gridCell.setFound(value);
  }
  public hideSelector(): void {
    this.clearSelector();
  }
  public clearSelector(): void {
    if (this.editing) {
      this.input.blur();
      this.editing = false;
    }
    this.selection.from = undefined;
    this.selection.to = undefined;
    this.selection.rowFrom = -1;
    this.selection.rowTo = -1;
    this.selection.colFrom = -1;
    this.selection.colTo = -1;
    this.Page.selectedCols = [];
    this.Page.selectedRows = [];
    this.isFocus = this.hasFocus = false;
    this.updateSelector();
    requestAnimationFrame(this.render);
  }
  public setSelectorByRef(str: string): void {
    let range = helpers.cellRange(str, this.Page.rows, this.Page.cols);
    // let cellFrom = this.content[range.from.row][range.from.col];
    // let cellTo = this.content[range.to.row][range.to.col];
    let from = this.getGridCell(range.from.row, range.from.col);
    let to = this.getGridCell(range.to.row, range.to.col);
    if (!from || !to) return;
    this.selection.from = from;
    this.selection.to = to;
    this.updateSelector();
    if (!this.keepSelectorInView()) requestAnimationFrame(this.render);
  }
  public setSelectorByIndex(
    fromIndex: IPageContentCellIndex,
    toIndex?: IPageContentCellIndex
  ): void {
    // let range = helpers.cellRange(str, this.Page.rows, this.Page.cols);
    // let cellFrom = this.content[range.from.row][range.from.col];
    // let cellTo = this.content[range.to.row][range.to.col];
    let from = this.getGridCell(fromIndex.row, fromIndex.col);
    let to = this.getGridCell(
      toIndex ? toIndex.row : fromIndex.row,
      toIndex ? toIndex.col : fromIndex.col
    );
    if (!from || !to) return;
    this.selection.from = from;
    this.selection.to = to;
    this.updateSelector();
    if (!this.keepSelectorInView()) requestAnimationFrame(this.render);
  }
  public getSelection(event?: any): IGridEventCellSelected {
    if (!this.selection.from || !this.selection.to) {
      throw new Error("Selection not found");
    }
    let reference: string = helpers.getCellsReference(
      this.selection.from!.position,
      this.selection.to!.position,
      ""
    );
    let count = 0;
    if (this.selection.heading) {
      let from = "";
      let to = "";
      if (this.selection.type === "col") {
        from = helpers.toColumnName(this.selection.from.col + 1);
        to = helpers.toColumnName(this.selection.to.col + 1);
        count = this.selection.colTo + 1 - this.selection.colFrom;
      }
      if (this.selection.type === "row" || this.selection.type === "all") {
        from = `${this.selection.from.row + 1}`;
        to = `${this.selection.to.row + 1}`;
        count = this.selection.rowTo + 1 - this.selection.rowFrom;
      }
      reference = from == to ? from : `${from}:${to}`;
    }
    return {
      reference,
      selection: {
        from: this.selection.from!.getDetails(),
        to: this.selection.to!.getDetails(),
        heading: this.selection.heading,
        inside: this.selection.inside,
        reference,
        keyboard: this.selection.keyboard,
        type: this.selection.type,
      },
      rightClick: this.rightClick,
      event,
      which: this.selection.type,
      count,
      multiple: this.selection.from!.reference != this.selection.to!.reference,
      colFrom: this.selection.colFrom,
      colTo: this.selection.colTo,
      rowFrom: this.selection.rowFrom,
      rowTo: this.selection.rowTo,
    };
  }
  public editCell(
    row: number,
    col: number,
    value?: string,
    click?: boolean
  ): void {
    let gridCell = this.getGridCell(row, col);
    if (!gridCell || !this.canEdit || this.rightClick) return;
    if (this.Page.columnsDefs.length && !gridCell.row) return;
    if (
      gridCell.button &&
      ["checkbox", "select", "rotate", "event"].includes(gridCell.button.type)
    ) {
      if (gridCell.button.type === "checkbox") {
        if (
          (this.rowChecked &&
            !gridCell.button.checkboxToggle &&
            gridCell.permission == "rw") ||
          gridCell.button.checkboxToggle
        )
          gridCell.onButton();
      }
      if (gridCell.permission != "rw") return;
      if (
        gridCell.button.type === "select" ||
        (gridCell.button.type === "event" && gridCell.button.options.length > 1)
      ) {
        gridCell.openOptions();

        // attach popper to parent
        if (this.popperParent)
          this.popperParent.appendChild(gridCell.cellPopper);
        else this.pageEl.appendChild(gridCell.cellPopper);

        // let timer: any = setInterval(()=>{
        //   if (!gridCell || !gridCell.cellPopper.parentElement) return;
        //   clearInterval(timer);
        //   timer = null;
        this.buttonPopper = new Popper(gridCell.cell, gridCell.cellPopper, {
          placement: "bottom-start",
          modifiers: {
            preventOverflow: {
              boundariesElement: "window",
            },
          },
        });
        // }, 10);
        if (gridCell.button.type != "event") this.editing = true;
      }
      if (gridCell.button.type === "rotate") {
        gridCell.onButton();
      }
    } else if (!click || (click && this.doubleClicked)) {
      if (gridCell.permission != "rw") return;
      // gridCell.edit(value || this.keyValue);
      this.editValue = value || this.keyValue;
      // this.input.value = '';
      this.input.style.cssText = gridCell.getInputStyles();
      if (!this.keyValue)
        this.input.value =
          `${gridCell.content.formatted_value}` || `${gridCell.content.value}`;
      this.editDone = this.keyValue || this.Page.touch ? true : false;
      this.input.style.setProperty("visibility", "visible");
      // if (this.Page.touch) {
      this.input.focus();
      // } else {
      //   let interval = setInterval(() => {
      //     if (!this.input.offsetParent) return;
      //     clearInterval(interval);
      //     this.input.focus();
      //   }, 20);
      // }
      this.editing = true;
    }
  }
  public getSelectedCells(): IGridCells {
    let cells: IGridCells = [];
    if (!this.selection.from || !this.selection.to) return cells;

      // this.grid.Page.selectedRows
      // this.grid.Page.selectedCols    

    for (let rowIndex = 0; rowIndex < this.Page.content.length; rowIndex++) {
      // if (!this.Page.selectedRows.includes(rowIndex)) continue;
      let row = this.Page.content[rowIndex];
      if (!this.Page.selectedRows.includes(row[0].index!.row)) continue;
      // if (
      //   row[0].index!.row < this.selection.rowFrom ||
      //   row[0].index!.row > this.selection.rowTo
      // )
      //   continue;
      let cellRow: IGridCell[] = [];
      for (let colIndex = 0; colIndex < row.length; colIndex++) {
        // if (!this.Page.selectedCols.includes(colIndex)) continue;
        if (!this.Page.selectedCols.includes(colIndex)) continue;
        // if (
        //   colIndex < this.selection.colFrom ||
        //   colIndex > this.selection.colTo
        // )
        //   continue;
        let cell: any = row[colIndex];
        let gridCell = this.getGridCell(cell.index.row, colIndex);
        if (gridCell) cellRow.push(gridCell);

      }
      cells.push(cellRow);
    }
    return cells;
  }
  public getGridCell(
    rowIndex: number,
    colIndex: number,
    ignore?: boolean
  ): IGridCell | null {
    if (
      !this.Page.originalContent[rowIndex] ||
      !this.Page.originalContent[rowIndex][colIndex]
    )
      return null;
    if (!this.gridCells[rowIndex]) {
      // return null;
      if (ignore) return null;
      this.gridCells[rowIndex] = [];
    }
    if (!this.gridCells[rowIndex][colIndex]) {
      // return null;
      if (ignore) return null;
      this.gridCells[rowIndex][colIndex] = new GridCell(
        this.Page,
        this.Events,
        rowIndex,
        colIndex
      );
      // this.gridCells[rowIndex][colIndex].update(rowIndex, colIndex, true);
      // if (this.rows[rowIndex])
      // this.gridCells[rowIndex][colIndex].rowIndex = this.rows[rowIndex].rowIndex;
    }
    return this.gridCells[rowIndex][colIndex];
  }

  private init(): void {
    this.reset();
    // this.destroy();
    this.dom();
    if (!this.initialized) this.createListeners();
  }
  private reset(): void {
    // this.clearSelector();

    this.destroyPopper();
    this.gridCells = [];
    this.rows = [];
    this.colRows = [];
    this.cornerRows = [];
    this.gridRowHeadingCells = [];
    this.gridColumnHeadingCells = [];
    this.colStyles = [];
    this.selection.from = undefined;
    this.selection.to = undefined;
    this.selection.rowFrom = -1;
    this.selection.rowTo = -1;
    this.selection.colFrom = -1;
    this.selection.colTo = -1;
    this.pageEl.innerHTML = "";
  }
  private createListeners(): void {
    window.addEventListener("resize", this.onResize);
    window.addEventListener("keydown", this.onKeydown);
    window.addEventListener("keyup", this.onKeyup);
    if (this.Page.touch) {
      window.addEventListener("touchstart", this.onMouseDown);
      window.addEventListener("touchend", this.onMouseUp);
      window.addEventListener("touchmove", this.onMouseMove);
    } else {
      window.addEventListener("mousedown", this.onMouseDown);
      window.addEventListener("mousemove", this.onMouseMove);
      window.addEventListener("mouseup", this.onMouseUp);
    }
    this.Events.on(this.Events.CELL, this.onCell);
  }
  private destroyListeners(): void {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("keydown", this.onKeydown);
    window.removeEventListener("keyup", this.onKeyup);
    if (this.Page.touch) {
      window.removeEventListener("touchstart", this.onMouseDown);
      window.removeEventListener("touchend", this.onMouseUp);
      window.removeEventListener("touchmove", this.onMouseMove);
    } else {
      window.removeEventListener("mousedown", this.onMouseDown);
      window.removeEventListener("mousemove", this.onMouseMove);
      window.removeEventListener("mouseup", this.onMouseUp);
    }
    // this.Events.off(this.Events.CELL, this.onCell);
    this.Events.removeEvents();
  }
  private dom(): void {
    // grid image
    this.Canvas = new GridCanvas(this.pageEl);

    // stylesheet
    this.styleSheet = document.createElement("style");
    this.styleSheet.type = "text/css";
    this.rowStyle = document.createTextNode(
      `.grid-${this.Page.uuid} .grid-row {}`
    );
    this.sortStyles = document.createTextNode("");
    this.styleSheet.appendChild(this.rowStyle);
    this.styleSheet.appendChild(this.sortStyles);
    this.pageEl.appendChild(this.styleSheet);

    this.getScrollbarWidth();
    this.pageEl.classList.add(this.contrast);
    this.pageEl.oncontextmenu = () => {
      return false;
    };
    this.pageElRect = this.pageEl.getBoundingClientRect();

    // scale
    // this.scaleEl = document.createElement('div');
    // this.scaleEl.className = 'grid-scale';
    // this.pageEl.appendChild(this.scaleEl);
    // edit el
    // this.editEl = document.createElement('div');
    // this.editEl.classList.add('grid-edit');
    // this.pageEl.appendChild(this.editEl);

    // scroller
    // this.scrollEl = document.createElement('div');
    // this.scrollEl.className = 'grid-scroller';
    // this.scrollEl.appendChild(this.boxEl);
    // this.pageEl.appendChild(this.scrollEl);

    // no matches
    this.noMatchesEl = document.createElement("div");
    this.noMatchesEl.className = "grid-no-matches";
    this.noMatchesEl.textContent = "No rows match filter";
    this.noMatchesEl.style.setProperty("display", "none");
    this.pageEl.appendChild(this.noMatchesEl);

    // container
    this.containerEl = document.createElement("div");
    this.containerEl.className = "grid-container";
    this.pageEl.appendChild(this.containerEl);
    this.mainEl = document.createElement("div");
    this.mainEl.className = "grid-main";
    this.freezeRowEl = document.createElement("div");
    this.freezeRowEl.className = "grid-freeze-rows";
    this.freezeColEl = document.createElement("div");
    this.freezeColEl.className = "grid-freeze-columns";
    this.freezeCornerEl = document.createElement("div");
    this.freezeCornerEl.className = "grid-freeze-corner";
    // this.headingRow = document.createElement('div');
    // this.headingRow.className = 'grid-heading-row';
    // this.stickyEl.appendChild(this.headingRow);
    this.containerEl.appendChild(this.mainEl);
    this.containerEl.appendChild(this.freezeRowEl);
    this.containerEl.appendChild(this.freezeColEl);
    this.containerEl.appendChild(this.freezeCornerEl);
    // this.containerEl.addEventListener('wheel', this.onScrollWheel);

    // scroll boxes
    this.boxEl = document.createElement("div");
    this.boxEl.className = "grid-box";
    this.mainEl.appendChild(this.boxEl);
    this.mainEl.addEventListener("scroll", this.onScroll);
    this.boxColEl = document.createElement("div");
    this.boxColEl.className = "grid-box";
    this.freezeColEl.appendChild(this.boxColEl);
    this.freezeColEl.addEventListener("wheel", this.onScrollFreezeCol);
    this.boxRowEl = document.createElement("div");
    this.boxRowEl.className = "grid-box";
    this.freezeRowEl.appendChild(this.boxRowEl);

    // selector
    // this.selectorWrapperEl = document.createElement('div');
    // this.selectorBoxEl = document.createElement('div');
    // this.selectorWrapperEl.className = 'grid-selector-wrapper';
    // this.selectorBoxEl.className = 'grid-box';
    this.selectorEl = document.createElement("div");
    this.selectorEl.className = "grid-selector";
    this.selectorEl.style.setProperty("display", "none");

    // input
    this.input = document.createElement("input");
    this.input.style.setProperty("visibility", "hidden");
    this.input.classList.add("grid-input");
    this.input.setAttribute("autocapitalize", "none");
    this.input.addEventListener("keydown", (evt) => {
      if (
        (["ArrowLeft", "ArrowUp", "ArrowRight", "ArrowDown"].indexOf(evt.key) >
          -1 &&
          this.editValue) ||
        ["Enter", "Tab"].indexOf(evt.key) > -1
      ) {
        this.editDone = true;
        this.input.blur();
        // this.emit(this.editDone, input.value);
        // this.emit(this.STOP, evt.key);
      } else if (evt.key === "Escape") {
        this.editDone = false;
        this.input.blur();
        this.Events.emit(this.Events.CELL_RESET, this.selection.from);
        evt.stopPropagation();
      } else if (["Home", "End"].indexOf(evt.key) > -1) {
        evt.stopPropagation();
      } else {
        this.editDone = true;
      }
      // console.log('keydown', this.editDone);
    });
    this.input.addEventListener("keyup", () => {
      // console.log('keyup', this.editDone);

      // this.emit(this.editValue, input.value);
      this.Events.emit(this.Events.CELL, {
        cell: this.selection.from,
        value: this.input.value,
        evt: {
          type: "value",
        },
      });
    });
    this.input.addEventListener("blur", (evt) => {
      // console.log('keyblur', this.editDone, this.selection.from!.content.formatted_value);
      if (this.editDone) {
        // this.content.formatted_value = this.content.value = this.oldValue;
        // this.content = { ...this.Page.originalContent[this.row][this.col]};
        // } else {
        this.selection.from!.content.formatted_value = this.selection.from!.content.value = this.input.value;
      }

      // this.editing = false;
      this.input.value = "";
      // this.input.parentElement!.removeChild(this.input);
      this.input.style.setProperty("visibility", "hidden");
      // this.cell.style.setProperty('z-index', `${this.params.zIndexCol}`);
      // this.editDone = false;
      // this.selection.from!.update(true);
      // TODO softmerges

      this.Events.emit(this.Events.CELL, {
        cell: this.selection.from,
        evt: {
          type: "blur",
        },
        done: this.editDone,
      });
    });
    this.input.addEventListener("focus", (evt) => {
      console.log("keyfocus");
      if (!this.editValue) {
        this.input.selectionStart = 0;
        this.input.selectionEnd = 999;
      } else {
        this.input.selectionEnd = 999;
      }
      console.log("input");
    });
    this.selectorEl.appendChild(this.input);

    this.containerEl.appendChild(this.selectorEl);
    // this.selectorWrapperEl.appendChild(this.selectorBoxEl);
    // this.pageEl.appendChild(this.selectorWrapperEl);

    // columns
    this.columnsEl = document.createElement("div");
    this.columnsEl.className = "grid-columns";
    this.columnSplitEl = document.createElement("div");
    this.columnSplitEl.className = "grid-column-split";
    this.containerEl.appendChild(this.columnsEl);
    this.containerEl.appendChild(this.columnSplitEl);

    // sort el
    this.sortWrapperEl = document.createElement("div");
    this.sortWrapperEl.classList.add("grid-sorting-wrapper");
    this.sortEl = document.createElement("div");
    this.sortEl.innerHTML = "<div></div>";
    this.sortEl.classList.add("grid-sorting");
    this.sortWrapperEl.appendChild(this.sortEl);
    // this.containerEl.appendChild(this.sortWrapperEl);
  }
  private getScrollbarWidth() {
    // Creating invisible container
    const outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.overflow = "scroll"; // forcing scrollbar to appear
    outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
    this.pageEl.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement("div");
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    // Removing temporary elements from the DOM
    outer.parentNode!.removeChild(outer);

    this.Page.scrollbarSize = scrollbarWidth;
  }
  private updateElSizes(): void {
    let offsetLeft = this.headings ? this.Page.headingsWidth : 0;
    let offsetTop = this.headings ? this.Page.headingsHeight : 0;
    this.Page.scrollbarWidth =
      this.Page.height + offsetTop > this.pageEl.offsetHeight
        ? this.Page.scrollbarSize
        : 0;
    this.Page.scrollbarHeight =
      this.Page.width + offsetLeft + this.Page.scrollbarWidth >
      this.pageEl.offsetWidth
        ? this.Page.scrollbarSize
        : 0;
    let width = this.headings
      ? this.Page.width - this.Page.headingsWidth - this.freezeColLeft
      : this.Page.width - this.freezeColLeft;
    let overflowX = this.Page.scrollbarHeight
      ? `bottom: ${this.Page.scrollbarHeight}px`
      : "";
    let overflowY = this.Page.scrollbarWidth
      ? `right: ${this.Page.scrollbarWidth}px`
      : "";
    this.rowStyle.textContent = `
      .grid-${this.Page.uuid}.headings .grid-freeze-corner .grid-row.cells {  
        transform: translate(0px, ${offsetTop}px)  
      }
      .grid-${this.Page.uuid} .grid-freeze-columns { 
        top: ${this.Page.filtersOffset}px;
        ${overflowX}
      }
      .grid-${this.Page.uuid}.headings .grid-freeze-columns { 
        top: ${offsetTop + this.Page.filtersOffset}px; 
      }
      .grid-${this.Page.uuid} .grid-freeze-corner { 
        
      }
      .grid-${this.Page.uuid} .grid-freeze-rows { 
        left: ${offsetLeft}px; ${overflowY} 
      } 
      .grid-${this.Page.uuid}.headings .grid-freeze-rows { 
        left: ${offsetLeft}px; ${overflowY} 
      } 
      .grid-${
        this.Page.uuid
      }.filters .grid-freeze-corner .grid-row.filter .grid-cell{ 
        transform: translate(${offsetLeft}px, 0px) 
      }
      .grid-${this.Page.uuid}.headings .grid-freeze-rows .grid-row.cells { 
        transform: translate(0px, ${offsetTop}px) 
      } 
      .grid-${this.Page.uuid} .grid-main { 
        left: ${offsetLeft}px; 
        top: ${this.freezeRowTop + offsetTop + this.Page.filtersOffset}px; 
      }
      .grid-${this.Page.uuid} .grid-main .grid-row { 
        transform: translateY(-${this.freezeRowTop}px)  
      }
      .grid-${this.Page.uuid} .grid-cell-heading.grid-cell-heading-col,
      .grid-${this.Page.uuid} .grid-cell-heading.grid-cell-heading-corner {
        height: ${offsetTop}px;
      }
      .grid-${this.Page.uuid} .grid-cell-heading.grid-cell-heading-row,
      .grid-${this.Page.uuid} .grid-cell-heading.grid-cell-heading-corner {
        width: ${this.Page.headingsWidth}px;
      }
    `;

    this.boxEl.style.setProperty(
      "width",
      `${this.Page.width - offsetLeft - this.freezeColLeft}px`
    );
    this.boxEl.style.setProperty("left", `${this.freezeColLeft}px`);
    this.boxEl.style.setProperty(
      "height",
      `${this.Page.height - offsetTop - this.freezeRowTop}px`
    );

    this.boxColEl.style.setProperty("width", `${width}px`);
    this.boxColEl.style.setProperty(
      "height",
      `${this.Page.height - offsetTop - this.freezeRowTop}px`
    );

    this.boxRowEl.style.setProperty(
      "width",
      `${this.Page.width - offsetLeft - this.freezeColLeft}px`
    );
    this.boxRowEl.style.setProperty("left", `${this.freezeColLeft}px`);
    this.boxRowEl.style.setProperty("height", `${this.Page.height}px`);
    // this.selectorBoxEl.style.setProperty('width', `${this.Page.width + width}px`);
    // this.selectorBoxEl.style.setProperty('height', `${this.Page.height + height}px`);
    // this.wrapperEl.style.setProperty('width', `${this.Page.width}px`);
    // this.wrapperEl.style.setProperty('height', `${this.Page.height}px`);
    // this.containerEl.style.setProperty('right', `${height}px`);
    // this.containerEl.style.setProperty('bottom', `${width}px`);
  }
  private updateSelector(): void {
    if (!this.selection.from || !this.selection.to) {
      this.selectorEl.style.setProperty("display", "none");
      this.Page.resetSelection();
      return;
    }

    // set selected rows and columns
    let fromRow = this.selection.from.rowIndex;
    let toRow = this.selection.to.rowIndex;

    let flipRow: boolean = toRow < fromRow;
    let flipCol: boolean = this.selection.to.col < this.selection.from.col;
    // console.log(flipRow, flipCol);

    this.selection.rowFrom = flipRow
      ? this.selection.to.rowIndex
      : this.selection.from.rowIndex;
    this.selection.rowTo = flipRow
      ? this.selection.from.rowIndex
      : this.selection.to.rowIndex;
    this.selection.colFrom = flipCol
      ? this.selection.to.col
      : this.selection.from.col;
    this.selection.colTo = flipCol
      ? this.selection.from.col
      : this.selection.to.col;

    this.Page.selectedRows = [];
    this.Page.selectedCols = [];
    for (
      let index = this.selection.rowFrom;
      index <= this.selection.rowTo;
      index++
    ) {
      this.Page.selectedRows.push(this.Page.content[index][0].index!.row);
    }
    for (
      let index = this.selection.colFrom;
      index <= this.selection.colTo;
      index++
    ) {
      this.Page.selectedCols.push(index);
    }

    // let from = this.getGridCell(this.selection.rowFrom, this.selection.colFrom)!;
    // let to = this.getGridCell(this.selection.rowTo, this.selection.colTo)!;

    // let pos = this.Page.cellPos(this.selection.rowFrom, this.selection.colFrom);
    // let pos2 = this.Page.cellPos(this.selection.rowTo, this.selection.colTo);

    // let width = pos2.x + this.selection.to.dataset.width - pos.x;
    // let height = pos2.y + this.selection.to.dataset.height - pos.y;
    let top = this.rows[this.selection.from.row].top;
    if (this.headings) top += this.Page.headingsHeight;
    if (this.selection.rowFrom >= this.freezeRow)
      top += this.Page.filtersOffset;
    let left = this.headings
      ? this.selection.from.dataset.x + this.Page.headingsWidth
      : this.selection.from.dataset.x;

    this.selectorEl.style.setProperty("left", `${left}px`);
    this.selectorEl.style.setProperty("top", `${top}px`);
    // this.selectorEl.style.setProperty('width', `${this.selection.from.dataset.width}px`);
    this.selectorEl.className = `grid-selector grid-col-${this.selection.from.col}`;
    let height = this.Page.rowHeights[this.selection.from.rowIndex];
    this.selectorEl.style.setProperty("height", `${height}px`);
    this.selectorEl.style.setProperty("display", "block");
    this.selectorEl.style.removeProperty("clip-path");
    // this.selectorEl.style.setProperty(
    //   'position',
    //   this.selection.from.col < this.freezeCol ? 'sticky' : 'absolute'
    // );
    if (
      this.selection.from.row < this.freezeRow &&
      this.selection.from.col < this.freezeCol
    ) {
      this.selectorEl.classList.add("corner");
    } else this.selectorEl.classList.remove("corner");
    if (
      this.selection.from.row < this.freezeRow &&
      this.selection.from.col >= this.freezeCol
    )
      this.selectorEl.classList.add("row");
    else this.selectorEl.classList.remove("row");
    if (
      this.selection.from.row >= this.freezeRow &&
      this.selection.from.col < this.freezeCol
    )
      this.selectorEl.classList.add("col");
    else this.selectorEl.classList.remove("col");
    this.scrollSelector();

    // let from = this.selection.from;
    // let to = this.selection.to || this.selection.from;
    // this.selectorEl.style.setProperty('display', 'block');
    // let pos = this.Page.cellPos(this.selection.from.row, this.selection.from.col);
    // this.selectorEl.style.setProperty('left', `${pos.x}px`);
    // this.selectorEl.style.setProperty('top', `${pos.y}px`);
    // let pos2 = this.Page.cellPos(to.row, to.col);
    // this.selectorEl.style.setProperty('width', `${to.dataset.width}px`);
    // this.selectorEl.style.setProperty('height', `${to.dataset.height}px`);

    this.Page.selection = this.selection;
  }
  private scrollSelector(): void {
    // if (this.state.sorting.enabled && this.state.hiddenColumns.indexOf(this.state.sorting.col) < 0) {
    //   // this.sortEl.style.setProperty('top', `${this.mainEl.scrollTop + 4}px`);
    //   if (this.state.sorting.col < this.freezeCol) {
    //     let left =
    //       (this.Page.colPos(this.state.sorting.col) + this.Page.colWidths[this.state.sorting.col] - 4) * this.scale;
    //     // this.sortEl.style.setProperty('left', `${left}px`);
    //   }
    // }

    if (!this.selection.from) return;

    if (this.selection.from.row >= this.freezeRow) {
      let top = this.rows[this.selection.from.row].top;
      if (this.headings) top += this.Page.headingsHeight;
      top += this.Page.filtersOffset;
      // top *= this.scale;
      top -= this.mainEl.scrollTop;
      this.selectorEl.style.setProperty("top", `${top}px`);
    }

    // left *= this.scale;
    if (this.selection.from.col >= this.freezeCol) {
      let left = this.headings
        ? this.selection.from.dataset.x + this.Page.headingsWidth
        : this.selection.from.dataset.x;
      left -= this.mainEl.scrollLeft;
      this.selectorEl.style.setProperty("left", `${left}px`);
    }
  }
  private keepSelectorInView(to?: boolean): boolean {
    if (!this.selection.from) return false;
    if (to && !this.selection.to) return false;
    let which = to ? "to" : "from";
    let row = this.selection[which].rowIndex;
    let col = this.selection[which].col;
    if (to) {
      if (!this.selection.heading || this.selection.type === "col") {
        if (this.selection.to!.col < this.selection.from.col) {
          col--;
          if (col < 0) col = 0;
        } else {
          col++;
          if (col >= this.Page.content[0].length)
            col = this.Page.content[0].length - 1;
        }
      }
      if (!this.selection.heading || this.selection.type === "row") {
        if (this.selection.to!.rowIndex < this.selection.from.rowIndex) {
          row--;
          if (row < 0) row = 0;
        } else {
          row++;
          if (row >= this.Page.content.length)
            row = this.Page.content.length - 1;
        }
      }
    }
    if (this.selection.type === "col") {
      row = this.selection.from.row;
    }
    if (this.selection.type === "row") {
      col = this.selection.from.col;
    }
    // let scrollLeft = this.getScrollLeft(col);
    // let scrollTop = this.getScrollTop(row);
    // console.log('keepSelectorInView', scrollLeft, scrollTop);
    // let scrolled = false;
    // if (scrollLeft >= 0 && scrollLeft != this.mainEl.scrollLeft) {
    //   this.mainEl.scrollLeft = scrollLeft;
    //   scrolled = true;
    // }
    // if (scrollTop >= 0 && scrollTop != this.mainEl.scrollTop) {
    //   this.mainEl.scrollTop = scrollTop;
    //   scrolled = true;
    // }
    return this.scrollTo(row, col);
  }
  private scrollTo(row: number, col: number): boolean {
    let scrollLeft = this.getScrollLeft(col);
    let scrollTop = this.getScrollTop(row);
    console.log("keepSelectorInView", scrollLeft, scrollTop);
    let scrolled = false;
    if (scrollLeft >= 0 && scrollLeft != this.mainEl.scrollLeft) {
      this.mainEl.scrollLeft = scrollLeft;
      scrolled = true;
    }
    if (scrollTop >= 0 && scrollTop != this.mainEl.scrollTop) {
      this.mainEl.scrollTop = scrollTop;
      scrolled = true;
    }
    return scrolled;
  }
  private applyState(refresh?: boolean): void {
    this.Page.content = this.Page.originalContent.concat();
    this.applyFilters();
    this.applySorting();
    let rowLen = this.Page.content.length;
    // this.Page.rows = rowLen;
    if (!this.Page.colWidths.length) {
      refresh = false;
    }
    if (!refresh) {
      this.Page.rowHeights = [];
      this.Page.colWidths = [];
    }
    this.Page.height = 0;
    this.Page.width = 0;
    this.Page.hiddenColumns = (this.state.hiddenColumns || []).sort();
    const cssCol: string[] = [];
    for (let visibleIndex = 0; visibleIndex < rowLen; visibleIndex++) {
      let row: any[] = this.Page.content[visibleIndex];
      let firstCell: IPageContentCell = row[0];
      let rowIndex = firstCell.index!.row;

      const h = parseFloat(`${firstCell.style.height}`);
      if (!h && !this.Page.hiddenRows.includes(rowIndex)) {
        this.Page.hiddenRows.push(rowIndex);
      }
      if (!refresh) {
        this.Page.rowHeights.push(h);
      }
      this.Page.height += h;
      let colLen = row.length;
      if (!visibleIndex) this.Page.cols = colLen;

      // setup/update row
      let gridRow: IGridRow;
      let gridColRow: IGridRow;
      let gridCornerRow: IGridRow;
      // check if corner row needs to be created
      if (rowIndex < this.freezeRow && !this.cornerRows[rowIndex]) {
        gridCornerRow = new GridRow(this.Page, this.Events, rowIndex);
        this.cornerRows[rowIndex] = gridCornerRow;
      }
      // create rows
      if (!this.rows[rowIndex]) {
        gridRow = new GridRow(this.Page, this.Events, rowIndex);
        this.rows[rowIndex] = gridRow;
        gridColRow = new GridRow(this.Page, this.Events, rowIndex);
        this.colRows[rowIndex] = gridColRow;
        if (!this.gridCells[rowIndex]) {
          this.gridCells[rowIndex] = [];
        }
      } else {
        gridRow = this.rows[rowIndex];
        gridColRow = this.colRows[rowIndex];
        gridCornerRow = this.cornerRows[rowIndex];
      }
      // if (gridRow.row < this.freezeRow) {
      //   this.stickyEl.appendChild(gridRow.rowEl);
      // }
      gridRow.setIndex(visibleIndex);
      // gridRow.setFreeze(gridRow.row < this.freezeRow);
      gridColRow.setIndex(visibleIndex);
      if (gridCornerRow!) gridCornerRow!.setIndex(visibleIndex);

      // create heading row cells
      if (!this.gridRowHeadingCells[rowIndex]) {
        this.gridRowHeadingCells[rowIndex] = new GridHeadingCell(
          this.Page,
          this.Events,
          rowIndex,
          0,
          "row"
        );
        this.gridRowHeadingCells[rowIndex].update(rowIndex, 0, true);
      }

      for (let colIndex = 0; colIndex < colLen; colIndex++) {
        let col: IPageContentCell = row[colIndex];

        if (!visibleIndex) {
          if (this.gridColumnHeadingCells[colIndex]) {
            this.gridColumnHeadingCells[colIndex].update(rowIndex, colIndex);
            // this.gridColumnHeadingCells[colIndex].setFreeze(colIndex < this.freezeCol);
          }

          let w = parseFloat(`${col.style.width}`);
          // hidden column from excel
          if (!w && !this.Page.hiddenColumns.includes(colIndex)) {
            this.Page.hiddenColumns.push(colIndex);
          }
          // check column defs
          let colDefW = this.Page.getColDefWidth(colIndex);
          if (colDefW) w = colDefW;
          if (!refresh) {
            if (this.Page.hiddenColumns.includes(colIndex)) {
              w = 0;
            }
            this.Page.colWidths.push(w);
            // cssCol.push(`.grid-${this.Page.uuid}-col-${colIndex} { width: ${w}px }`);
            // this.colStyles[colIndex].textContent = `.grid-${this.Page.uuid} .grid-col-${colIndex} { width: ${w}px }`;
          } else {
            w = this.Page.colWidths[colIndex];
          }
          this.applyColWidth(colIndex, w);
          this.Page.width += w;
        }

        if (!this.gridCells[rowIndex][colIndex])
          this.gridCells[rowIndex][colIndex] = new GridCell(
            this.Page,
            this.Events,
            rowIndex,
            colIndex
          );
        // this.gridCells[rowIndex][colIndex].setData();
        this.gridCells[rowIndex][colIndex].rowIndex = visibleIndex;

        // get largest border
        if (colIndex == colLen - 1) {
          const borderWidths = this.gridCells[rowIndex][
            colIndex
          ].getBorderWidths();
          if (borderWidths.r > this.Page.borderRightWidth) {
            this.Page.borderRightWidth = borderWidths.r;
          }
          if (borderWidths.b > this.Page.borderBottomWidth) {
            this.Page.borderBottomWidth = borderWidths.b;
          }
        }

        // filter cells
        // if (!this.gridFilterCells[0][colIndex])
      }
    }
    this.gridCornerHeadingCell.update(0, 0);
    // this.styleSheet.textContent = cssCol.join('');
    this.Page.rows = rowLen;
    this.width = this.Page.width;
    this.height = this.Page.height;
    this.content = this.Page.content;
    this.freezeRowTop = this.Page.rowPos(this.freezeRow);
    this.freezeColLeft = this.Page.colPos(this.freezeCol);
    this.gridRowFilters.setTop(this.freezeRowTop);
    this.gridCornerRowFilters.setTop(this.freezeRowTop);
    this.updateElSizes();

    // sorting indicator
    // if (this.state.sorting.enabled && this.state.hiddenColumns.indexOf(this.state.sorting.col) < 0) {
    //   this.pageEl.classList.add('sorting');
    //   this.sortEl.classList.add(`sorting-${this.state.sorting.direction}`);
    //   let cell = this.Page.content[0][this.state.sorting.col];
    //   let gridCell = this.getGridCell(cell.index.row, cell.index.col);
    //   if (gridCell) {
    //     // this.sortEl.style.setProperty('left', `${gridCell.dataset.x + this.Page.colWidths[cell.index.col] - 4}px`);
    //     gridCell.cell.appendChild(this.sortWrapperEl);
    //   }
    // } else {
    //   if (this.sortWrapperEl.parentElement) this.sortWrapperEl.parentElement.removeChild(this.sortWrapperEl);
    //   this.pageEl.classList.remove('sorting');
    //   this.sortEl.classList.remove('sorting-asc', 'sorting-desc');
    // }
  }
  private applyColWidth(colIndex: number, width: number): void {
    let text = width
      ? `width: ${width}px; left: ${this.Page.colPos(colIndex)}px`
      : "display: none !important";
    this.colStyles[
      colIndex
    ].textContent = `.grid-${this.Page.uuid} .grid-col-${colIndex} { ${text} }`;
  }
  private applySortIndicator(): void {
    // sorting indicator
    if (
      this.state.sorting.enabled &&
      this.state.hiddenColumns.indexOf(this.state.sorting.col) < 0
    ) {
      this.pageEl.classList.add("sorting");
      this.sortEl.classList.add(`sorting-${this.state.sorting.direction}`);
      // check if cell exists

      let interval: any = setInterval(() => {
        let row: number = this.freezeRow ? this.freezeRow - 1 : 0;
        let cell = this.Page.content[row][this.state.sorting.col];
        let gridCell = this.getGridCell(cell.index.row, cell.index.col);
        if (!gridCell || !gridCell.cell) return;
        gridCell.cell.appendChild(this.sortWrapperEl);
        clearInterval(interval);
        interval = null;
      }, 50);
    } else {
      if (this.sortWrapperEl.parentElement)
        this.sortWrapperEl.parentElement.removeChild(this.sortWrapperEl);
      this.pageEl.classList.remove("sorting");
      this.sortEl.classList.remove("sorting-asc", "sorting-desc");
    }
  }
  private applyFilters(): void {
    this.Page.hiddenRows = [];
    // let originalData = JSON.parse(JSON.stringify(content));
    if (!this.state.filters.length) {
      return;
    }
    // let data = [];

    let rowsToRemove: number[] = [];
    // let rowOffset = this.Grid.Settings.headings ? 1 : 0;

    for (
      let rowIndex = 0;
      rowIndex < this.Page.originalContent.length;
      rowIndex++
    ) {
      // let offset = this.Grid.Settings.headings ? -1 : 0;
      if (rowIndex < this.freezeRow) {
        // rowsToKeep.push(rowIndex);
        continue;
      }

      for (let i = 0; i < this.state.filters.length; i++) {
        let filter: IGridFilter = this.state.filters[i];
        if (filter.enabled === false) {
          continue;
        }
        let col = this.Page.originalContent[rowIndex][filter.col];
        // if (!col || rowsToRemove.indexOf(rowIndex) > -1) continue;
        if (!col) continue;
        let colValue: any =
          filter.type === "number"
            ? helpers.convertToNumber(`${col.value}`)
            : `${col.formatted_value || col.value}`;
        let filterValue: any =
          filter.type === "number"
            ? helpers.convertToNumber(filter.value)
            : `${filter.value}`;

        let validValue = false;
        switch (filter.type) {
          case "number":
            try {
              validValue = eval(`${colValue} ${filter.exp} ${filterValue}`);
            } catch (e) {
              validValue = false;
            }
            break;
          case "date":
            let dates = filterValue.split(",");
            let fromDate = moment(dates[0], filter.dateFormat).toISOString();
            let toDate = moment(
              dates[1] || dates[0],
              filter.dateFormat
            ).toISOString();

            // function
            let regex = new RegExp(`=DATETIME\((.*)\)`, "gmi");
            if (regex.test(filterValue)) {
              const regex = /=DATETIME\((.*)\)/gim;
              let m;
              let params = "";
              while ((m = regex.exec(filterValue)) !== null) {
                if (m.index === regex.lastIndex) {
                  regex.lastIndex++;
                }
                params = m[1];
              }
              let args: any = params.split(",");
              let mo: any = moment
                .tz(eval(args[1]) || "UTC")
                .add(eval(args[2]) || 0, eval(args[3]) || "minutes");
              // .format(args[0]);
              filter.dateFormat = args[0];
              fromDate = toDate = mo.toISOString();
            }

            let dateString = colValue;
            let date: any = new Date(dateString);
            if (helpers.isNumber(colValue)) {
              dateString = helpers.parseDateExcel(colValue);
              date = new Date(dateString);
            } else if (filter.dateFormat) {
              // eg: DD MMMM YYYY (01 January 2019)
              date = moment(colValue, filter.dateFormat);
            }
            if (date.toString() === "Invalid Date") break;
            if (filter.exp === "between") {
              validValue =
                date.toISOString() >= fromDate && date.toISOString() <= toDate;
            } else {
              try {
                validValue = eval(
                  `'${date.toISOString()}' ${filter.exp} '${fromDate}'`
                );
              } catch (e) {
                validValue = false;
              }
            }
            break;
          default:
            filterValue.split(",").forEach((value: any) => {
              if (validValue) {
                return;
              }
              switch (filter.exp) {
                case "contains":
                  validValue =
                    colValue.toLowerCase().indexOf(value.toLowerCase()) > -1;
                  break;
                case "starts_with":
                  validValue =
                    colValue.toLowerCase().substring(0, value.length) ===
                    value.toLowerCase();
                  break;
                case "!=":
                  validValue = colValue != value; // eval(`"${colValue}" == "${value}"`);
                  break;
                default:
                  validValue = colValue == value; // eval(`"${colValue}" == "${value}"`);
                  break;
              }
            });
            break;
        }
        if (!validValue && rowsToRemove.indexOf(rowIndex) < 0) {
          rowsToRemove.push(col.index!.row);
        }
        // if (!validValue) {
        //   this.Page.content.splice(rowIndex, 1);
        // }
      }
    }
    for (let i = rowsToRemove.length - 1; i >= 0; i--)
      this.Page.content.splice(rowsToRemove[i], 1);
    this.Page.hiddenRows = rowsToRemove;
    this.rowsToHide = rowsToRemove;
    // return content;
    // return data;
  }
  private applySorting(): void {
    // if (!this.state.sorting || !this.state.sorting.enabled) return content;
    // let key = Object.keys(this.state.sorting)[0];
    let filter = this.state.sorting;
    let data = this.freezeRow
      ? this.Page.content.slice(this.freezeRow, this.Page.content.length)
      : this.Page.content.slice(0);
    let exp = filter.direction === "desc" ? ">" : "<";
    let opp = filter.direction === "desc" ? "<" : ">";
    data.sort((a, b) => {
      if (!this.state.sorting.enabled) {
        return a[0].index.row - b[0].index.row;
      }
      let col = filter.col;
      if (!a || !b || !a[col] || !b[col]) {
        return 1;
      }
      let aVal =
        filter.type === "number"
          ? parseFloat(a[col].value) || 0
          : `"${a[col].formatted_value}"`;
      let bVal =
        filter.type === "number"
          ? parseFloat(b[col].value) || 0
          : `"${b[col].formatted_value}"`;

      if (filter.type === "date") {
        aVal = `"${moment(
          a[col].formatted_value,
          filter.dateFormat
        ).toISOString()}"`;
        bVal = `"${moment(
          b[col].formatted_value,
          filter.dateFormat
        ).toISOString()}"`;
      }

      try {
        if (eval(`${aVal} ${exp} ${bVal}`)) {
          return -1;
        }
        if (eval(`${aVal} ${opp} ${bVal}`)) {
          return 1;
        }
      } catch (e) {
        return 0;
      }
      return 0;
    });

    this.Page.content = this.freezeRow
      ? this.Page.content.slice(0, this.freezeRow).concat(data)
      : data;
  }
  private onScrollFreezeCol = (evt: any) => {
    this.mainEl.scrollTop += evt.deltaY;
    this.mainEl.scrollLeft += evt.deltaX;
    console.log(evt);
  };
  private onScroll = (evt: any) => {
    this.freezeColEl.scrollTop = this.mainEl.scrollTop;
    this.freezeRowEl.scrollLeft = this.mainEl.scrollLeft;
    // if (this.mainEl.scrollTop < 0 || this.mainEl.scrollLeft < 0) {
    //   return;
    // }
    // if (this.scrollTimer) {
    //   clearTimeout(this.scrollTimer);
    //   this.scrollTimer = null;
    // }
    // setTimeout(()=>{
    // console.log('scroll', this.mainEl.scrollTop);
    this.destroyPopper();
    this.scrollSelector();

    // this.render();
    requestAnimationFrame(this.render);
    // }, 100)
    this.Page.scrollTop = this.mainEl.scrollTop;
    this.Page.scrollLeft = this.mainEl.scrollLeft;
    this.applyMergeWidths();
  };
  private applyMergeWidths() {
    // merged cells
    this.visibleRows.forEach((rowIndex) => {
      let gridCell = this.getGridCell(rowIndex, this.freezeCol - 1);
      if (!gridCell) return;
      gridCell.updateMergeWidth(this.mainEl.scrollLeft, this.freezeColLeft);
    });
  }
  private onCell = (data: IGridCellEvent) => {
    console.log("onCell", data);

    // if (this.disallowSelection) {
    //   return;
    // }

    // soft merge
    if (data.evt.type == "merge") {
      // console.log('merge', data);
      this.gridCells[data.cell.row].forEach((gridCell) => {
        gridCell.updateSoftMerge(data.data.left, "left", data.data.zIndex);
        gridCell.updateSoftMerge(data.data.right);
      });
    }

    // cell value has changed
    if (data.evt.type == "value") {
      let event: IGridEventCellValue = {
        cell: data.cell,
        content: data.cell.content,
        value: data.value || "",
      };
      this.Events.emit(this.Events.CELL_VALUE, event);
      this.dirty = true;
      return;
    }

    if (data.evt.type === "check") {
      let event: IGridEventCellChecked = {
        cell: data.cell.getDetails(),
        content: data.cell.content,
        value: data.value,
        checked: data.checked,
      };
      this.Events.emit(this.Events.CELL_CHECKED, event);
      return;
    }

    if (data.evt.type === "filter_focus") {
      this.Events.emit(this.Events.COLUMN_FILTER_FOCUS, data);
    }
    if (data.evt.type === "filter") {
      console.log(data);
      // let name = `grid-${data.cell.col}`;
      let exp = "contains";
      let type = IGridFieldType.STRING;
      if (data.value.indexOf(">") === 0) {
        exp = data.value.indexOf("=") === 1 ? ">=" : ">";
        data.value = data.value.replace(">", "").replace("=", "");
        type = IGridFieldType.NUMBER;
      }
      if (data.value.indexOf("<") === 0) {
        exp = data.value.indexOf("=") === 1 ? "<=" : "<";
        data.value = data.value.replace("<", "").replace("=", "");
        type = IGridFieldType.NUMBER;
      }
      if (data.value.indexOf("^") === 0) {
        exp = "starts_with";
        data.value = data.value.replace("^", "");
      }
      if (data.value.indexOf("=") === 0) {
        exp = "==";
        data.value = data.value.replace("=", "");
      }
      if (data.value.indexOf("!=") === 0) {
        exp = "!=";
        data.value = data.value.replace("!=", "");
      }
      if (data.value.indexOf(",") > -1) exp = "matches";
      let filter: IGridFilter = {
        type,
        exp,
        col: data.cell.col,
        value: data.value,
        // name
      };
      let index = this.state.filters.findIndex((f) => {
        return f.col == data.cell.col;
      });
      if (!data.value && index > -1) {
        this.state.filters.splice(index, 1);
      } else if (data.value) {
        if (index > -1) {
          this.state.filters[index] = filter;
        } else {
          this.state.filters.push(filter);
        }
      }
      this.Events.emit(this.Events.COLUMN_FILTER, filter);
      this.updateState(this.state, true);
      return;
    }

    if (data.evt.type === "option") {
      let event: IGridEventCellOption = {
        cell: data.cell.getDetails(),
        content: data.cell.content,
        option: data.option || "",
      };
      this.destroyPopper();
      this.Events.emit(this.Events.CELL_OPTION, event);
      return;
    }

    // cell editing has finished
    if (data.evt.type === "blur") {
      this.dirty = data.done || false;
      this.editing = false;
      this.gridCells[data.cell.row].forEach((gridCell) => {
        gridCell.update(true);
      });
      return;
    }

    // sorting
    if (data.evt.type === "sort") {
      // this.sortColFromPos = this.Page.colPos(this.selection.colFrom);
      let width = 0;
      for (let i = this.selection.colFrom; i <= this.selection.colTo; i++) {
        width += this.Page.colWidths[i];
      }
      this.sortColWidth = width;
      this.sortColLeft =
        this.Page.colPos(this.selection.colFrom) + this.Page.headingsWidth;
      if (this.selection.colFrom >= this.freezeCol) {
        this.sortColLeft -= this.mainEl.scrollLeft;
      }
      this.pageEl.classList.add("sorting-columns");
      this.columnsEl.style.setProperty("width", `${this.sortColWidth}px`);
      this.columnsEl.style.setProperty("left", `${this.sortColLeft}px`);
      this.columnsEl.style.setProperty(
        "height",
        `${this.Page.height + this.Page.headingsHeight}px`
      );
      this.columnSplitEl.style.setProperty("left", `${this.sortColLeft}px`);
      this.columnSplitEl.style.setProperty(
        "height",
        `${this.Page.height + this.Page.headingsHeight}px`
      );
    }
    if (data.evt.type === "sorting") {
      let left = this.sortColLeft + data.data.offsetX;
      this.columnsEl.style.setProperty("left", `${left}px`);
      if (this.sortColScroll) return;
      let width = this.Page.headingsWidth - this.mainEl.scrollLeft;
      let colIndex = -1;
      this.Page.colWidths.forEach((w, i) => {
        if (colIndex > -1) return;
        if (data.data.offsetX < 0 && width > left) {
          colIndex = i;
        }
        width += w;
        if (data.data.offsetX > 0 && width > left + this.sortColWidth) {
          colIndex = i;
        }
      });
      // bump to end of page
      if (colIndex < 0 && data.data.offsetX > 0) {
        colIndex = this.Page.content[0].length;
      }
      // console.log(
      //   colIndex,
      //   helpers.toColumnName(colIndex + 1),
      //   this.Page.colPos(colIndex)
      // );
      // auto scroll
      if (
        this.scrollTo(0, data.data.offsetX > 0 ? colIndex + 1 : colIndex - 1)
      ) {
        this.sortColScroll = true;
        setTimeout(() => {
          this.sortColScroll = false;
        }, 100);
      }
      // check scroll pos
      let splitOffsetX = this.Page.colPos(colIndex) + this.Page.headingsWidth;
      if (colIndex >= this.freezeCol) {
        splitOffsetX -= this.mainEl.scrollLeft;
      }

      // this.columnsEl.textContent = `${left}px - ${left + this.colWidth}`;
      if (
        (colIndex > -1 && colIndex < this.selection.colFrom) ||
        colIndex > this.selection.colTo + 1
      ) {
        this.columnSplitEl.style.setProperty("left", `${splitOffsetX}px`);
        this.columnSplitEl.style.setProperty("display", "block");
      } else {
        this.columnSplitEl.style.setProperty("display", "none");
      }
      this.sortColMoveTo = colIndex;
    }
    if (data.evt.type === "sorted") {
      this.pageEl.classList.remove("sorting-columns");
      this.columnSplitEl.style.setProperty("display", "none");
      if (!this.selection.from || this.sortColMoveTo < 0) return;
      this.Events.emit(this.Events.COLUMNS_MOVED, {
        index: this.sortColMoveTo,
      });
      // console.log(this.selection.from.col, this.sortColMoveTo);
    }

    // resizing
    if (data.evt.type === "resize") {
      console.log("resize", data);
      this.clearSelector();
      requestAnimationFrame(this.render);
      this.Events.emit(this.Events.RESIZE, {
        which: data.cell.type,
        index: data.cell.type === "col" ? data.cell.col : data.cell.row,
        data: data.data,
      });
      return;
    }
    if (data.evt.type === "resizing") {
      let cellSize = 0;
      console.log("resizing", data);
      if (!data.data) return;
      if (data.data.type === "col") {
        cellSize = data.data.width;
        this.updateColWidth(data.cell.col, cellSize);
      }
      if (data.data.type === "row") {
        cellSize = data.data.height;
        this.Page.rowHeights[data.cell.rowIndex] = cellSize;
        this.rows[data.cell.row].setHeight(cellSize);
        this.colRows[data.cell.row].setHeight(cellSize);
        if (this.cornerRows[data.cell.row]) {
          this.cornerRows[data.cell.row].setHeight(cellSize);
        }
        this.Page.content.forEach((row, visibleIndex) => {
          let rowIndex = row[0].index.row;
          if (visibleIndex <= data.cell.rowIndex) return;
          this.rows[rowIndex].setTop();
          this.colRows[rowIndex].setTop();
          if (this.cornerRows[rowIndex]) {
            this.cornerRows[rowIndex].setTop();
          }
        });
        // cellSize = data.data.width;
        // this.applyColWidth(data.cell.col, cellSize);
      }
      this.Events.emit(this.Events.RESIZING, {
        which: data.cell.type,
        index: data.cell.type === "col" ? data.cell.col : data.cell.row,
        data: data.data,
      });
      return;
    }
    if (data.evt.type === "resized") {
      console.log("resized", data);
      let cellSize = 0;
      if (data.cell.type === "col") {
        cellSize = data.data.width;
        this.Page.colWidths[data.cell.col] = cellSize;
      }
      if (data.cell.type === "row") {
        // this.Page.colWidths[data.cell.row] = cellSize;
        cellSize = data.data.height;
        this.Page.rowHeights[data.cell.row] = cellSize;
      }
      if (data.data.offsetX || data.data.offsetY) {
        this.Events.emit(this.Events.RESIZED, {
          which: data.cell.type,
          index: data.cell.type === "col" ? data.cell.col : data.cell.row,
          size: cellSize,
        });
        this.forceUpdate = true;
        this.update(true);
      }
      return;
    }

    if (data.evt.touches) {
      if (data.evt.touches.length == 2) {
        return;
      }
    }

    // if (data.cell.editing) {
    //   return;
    // }

    if (["mousedown", "touchstart"].includes(data.evt.type)) {
      this.destroyPopper();
      this.rightClick = this.isRightClick(data.evt);
      this.isFocus = this.hasFocus = true;
      let d = new Date();
      console.log("CELL", data.cell.reference);
      this.doubleClicked = d.getTime() - this.clickedAt <= 300;
      this.clickedAt = d.getTime();
      this.historyClick = data.evt.target.classList.contains("grid-history");
      this.filterClick = data.evt.target.classList.contains("filters");
      this.rowChecked = data.evt.target.classList.contains("checkbox");

      // check if diff
      if (this.selection.from) {
        if (this.editing) {
          // if (data.cell.reference != this.selection.from.reference) {
          if (this.editDone) {
            this.input.blur();
          } else {
            this.editing = false;
          }
          // this.selection.from.cancel();
        }
      }

      // check if inside current selection
      this.selection.selected = true;
      // let heading = false;
      let gridCell = data.cell;
      if (
        this.rightClick &&
        this.Page.selectedRows.includes(data.cell.row) &&
        this.Page.selectedCols.includes(data.cell.col)
      ) {
        this.selection.inside = true;
      } else {
        this.selection.inside = false;

        if (data.cell.type === "col") {
          // let c = this.Page.content[0][data.cell.col];
          gridCell = this.gridCells[0][data.cell.col];
          // heading = true;
        } else if (data.cell.type === "row") {
          // let c = this.Page.content[data.cell.row][0];
          gridCell = this.gridCells[data.cell.row][0];
          // heading = true;
        } else if (data.cell.type === "corner") {
          let c = this.Page.content[0][0];
          gridCell = this.gridCells[c.index.row][c.index.col];
          // heading = true;
        }

        this.selection.from = gridCell;
        this.selection.to = gridCell;
        if (!this.Page.touch && !this.disallowSelection) {
          this.updateSelector();
          requestAnimationFrame(this.render);
        }
      }
      this.selection.heading = data.cell.type !== "cell";
      this.selection.type = data.cell.type;

      // this.Page.setSelectedCell(data);
      this.Events.emit(this.Events.CELL_CLICKED, {
        cell: gridCell,
        event: data.evt,
        content: this.selection.from!.content,
        rightClick: this.rightClick,
        historyClick: this.historyClick,
        filterClick: this.filterClick,
        heading: this.selection.heading,
      });

      // data.evt.preventDefault();
    }

    if (["mouseover", "mousemove"].includes(data.evt.type)) {
      if (this.selection.selected && !this.selection.inside) {
        // console.log(data.evt.target.dataset.row, data.evt.target.dataset.col);
        // this.selection.to = data.cell;
        this.setToCell(data);
        if (!this.disallowSelection) {
          this.updateSelector();
          if (!this.Page.touch && !this.keepSelectorInView(true)) {
            requestAnimationFrame(this.render);
          }
        }
        // console.log(this.selection.to?.reference);
      }
    }
    if (["mouseup", "touchend"].includes(data.evt.type)) {
      console.log("mouseup", data);
      if (!this.hasFocus || !this.selection.from) {
        return;
      }
      if (this.selection.selected && !this.selection.inside) {
        this.setToCell(data);
      }
      this.selection.selected = false;
      if (!this.disallowSelection) {
        this.updateSelector();
        requestAnimationFrame(this.render);
      }
      this.emitCellSelected(data.evt);

      // edit cell
      if (this.selection.from!.reference == this.selection.to!.reference) {
        this.editCell(
          this.selection.from!.row,
          this.selection.from!.col,
          undefined,
          true
        );
        if (this.editing) {
          if (!this.Page.touch) data.evt.preventDefault();
          else
            setTimeout(() => {
              this.keepSelectorInView();
            }, 300);
        }
      }
      this.historyClick = false;
      this.filterClick = false;
      this.rightClick = false;
      this.doubleClicked = false;
      this.selection.heading = false;
      data.evt.stopPropagation();
    }
  };

  private setToCell(data: IGridCellEvent): void {
    let gridCell: IGridCell | null;
    if (data.cell.type === "col") {
      let c = this.Page.content[this.Page.content.length - 1][data.cell.col];
      gridCell = this.getGridCell(c.index.row, c.index.col);
      this.selection.type = "col";
    } else if (data.cell.type === "row") {
      // let c = this.Page.content[data.cell.row][this.Page.content[0].length - 1];
      gridCell = this.getGridCell(
        data.cell.row,
        this.Page.content[0].length - 1
      );
      this.selection.type = "row";
    } else if (data.cell.type === "corner") {
      let c = this.Page.content[this.Page.content.length - 1][
        this.Page.content[0].length - 1
      ];
      gridCell = this.getGridCell(c.index.row, c.index.col);
      this.selection.type = "all";
    } else {
      gridCell = data.cell;
      this.selection.heading = false;
    }
    if (gridCell) this.selection.to = gridCell;
  }

  private render = () => {
    if (!this.gridCells.length) {
      this.forceUpdate = false;
      this.rendering = false;
      return;
    }
    const scrollTop = this.mainEl.scrollTop;
    const scrollLeft = this.mainEl.scrollLeft * this.scale;
    this.scrollTop = scrollTop;
    let visible: number[] = [];
    let visibleCols: number[] = [];
    let rowStart = -1;
    let rowEnd = -1;
    let colStart = -1;
    let colEnd = -1;
    let offsetHeight = this.pageEl.offsetHeight;
    let offsetWidth = this.pageEl.offsetWidth;

    // update selector
    // if (this.selection.from) {
    //   if (this.selection.from.col < this.freezeCol && this.selection.from.row >= this.freezeRow) {
    //     let top = this.rows[this.selection.from.row].top;
    //     // this.selectorEl.style.setProperty('top', `${this.selection.from.dataset.y - scrollTop}px`);
    //     // this.selectorEl.style.setProperty('top', `${top - scrollTop}px`);
    //   }
    // }

    // get visible rows
    this.Page.rowHeights.forEach((height, index) => {
      // let h = this.Page.rowHeights.slice(0, index + 1).reduce((a, b) => a + b, 0);
      let h = this.Page.rowPos(index) * this.scale;
      let show = true;
      let before = -offsetHeight / 4;
      let after = offsetHeight;
      // after *= this.scale;
      if (h - scrollTop * this.scale < before) show = false;
      if (h - scrollTop * this.scale - height * this.scale > after)
        show = false;
      if (show && rowStart < 0) rowStart = index;
      if (index < this.freezeRow) show = true;
      if (show) visible.push(index);
      if (!show && rowStart > -1 && rowEnd < 0) {
        rowEnd = index;
      }
    });

    // get visible cols
    this.Page.colWidths.forEach((width, index) => {
      // let h = this.Page.rowHeights.slice(0, index + 1).reduce((a, b) => a + b, 0);
      let w = this.Page.colPos(index) * this.scale;
      let show = true;
      let before = this.freezeColLeft * this.scale;
      let after = offsetWidth;
      // after *= this.scale;
      if (w - scrollLeft + width * this.scale < before) show = false;
      if (w - scrollLeft > after) show = false;
      if (show && colStart < 0) colStart = index;
      if (index < this.freezeCol) show = true;
      if (show) visibleCols.push(index);
      if (!show && colStart > -1 && colEnd < 0) {
        colEnd = index;
      }
    });
    // console.log('cols', visibleCols);

    this.gridColumnHeadingCells.forEach((gridCell) => {
      gridCell.setSelected(this.selection);
    });

    this.gridRowHeadingCells.forEach((gridCell) => {
      gridCell.setSelected(this.selection);
    });

    // remove invisble row
    // let difference  = visible.filter(x => !this.visibleRows.includes(x));
    // console.log('intersection', difference );
    // difference.forEach(rowIndex => {
    //   if (!this.rows[rowIndex] || !this.rows[rowIndex].rowEl.parentNode) return;
    //   this.rows[rowIndex].rowEl.parentNode!.removeChild(this.rows[rowIndex].rowEl);
    // })

    // create visible rows
    let mainFragment = document.createDocumentFragment();

    let freezeCornerFragment = document.createDocumentFragment();
    let freezeColumnFragment = document.createDocumentFragment();
    let freezeRowFragment = document.createDocumentFragment();
    let freezeCornerCellsFragment = document.createDocumentFragment();

    let appendRows = false;
    this.Page.content.forEach((row, visibleIndex) => {
      let rowIndex = row[0].index.row;
      let show = visible.includes(visibleIndex);

      let gridRow = this.rows[rowIndex];
      let gridColRow = this.colRows[rowIndex];
      let gridCornerRow = this.cornerRows[rowIndex];

      if (!show) {
        if (!gridRow.rowEl.parentElement) return;
        gridRow.rowEl.parentElement!.removeChild(gridRow.rowEl);
        gridColRow.rowEl.parentElement!.removeChild(gridColRow.rowEl);
        return;
      }

      // check if we need to append row
      if (gridRow.row < this.freezeRow) {
        if (!this.freezeRowEl.contains(gridRow.rowEl)) {
          freezeRowFragment.appendChild(gridRow.rowEl);
          freezeCornerFragment.appendChild(gridCornerRow.rowEl);
          appendRows = true;
        }
      } else if (!this.mainEl.contains(gridRow.rowEl)) {
        mainFragment.appendChild(gridRow.rowEl);
        appendRows = true;
      }
      if (!this.freezeColEl.contains(gridColRow.rowEl)) {
        freezeColumnFragment.appendChild(gridColRow.rowEl);
        // if (gridCornerRow) this.freezeCornerEl.appendChild(gridCornerRow.rowEl);
        appendRows = true;
      }
      // attach cells to row
      let cellsFragment = document.createDocumentFragment();
      let freezeColCellsFragment = document.createDocumentFragment();
      let freezeRowCellsFragment = document.createDocumentFragment();
      let appendCells = false;

      // row heading cell
      let gridRowCell: IGridHeadingCell = this.gridRowHeadingCells[rowIndex];

      // append row heading cell;
      if (rowIndex < this.freezeRow) {
        if (gridRowCell.cell.parentElement != gridCornerRow.rowEl) {
          gridCornerRow.rowEl.appendChild(gridRowCell.cell);
          appendCells = true;
        }
      } else {
        if (gridRowCell.cell.parentElement != gridColRow.rowEl) {
          freezeRowCellsFragment.appendChild(gridRowCell.cell);
          appendCells = true;
        }
        // freezeCornerFragment.appendChild(gridRowCell.cell);
      }
      gridRowCell.rowIndex = visibleIndex;

      // create/update cells
      for (let colIndex = 0; colIndex < this.Page.cols; colIndex++) {
        let gridCell: IGridCell = this.gridCells[rowIndex][colIndex];

        if (!visibleCols.includes(colIndex)) {
          gridCell.remove();
          continue;
        }

        // corner heading cells
        if (!visibleIndex && colIndex < this.freezeCol) {
          if (
            this.gridColumnHeadingCells[colIndex].cell.parentElement !=
            this.gridCornerHeading.rowEl
          ) {
            this.gridCornerHeading.rowEl.appendChild(
              this.gridColumnHeadingCells[colIndex].cell
            );
          }
          // filter cells
          if (
            this.gridFilterCells[colIndex].cell.parentElement !=
            this.gridCornerRowFilters.rowEl
          ) {
            this.gridCornerRowFilters.rowEl.appendChild(
              this.gridFilterCells[colIndex].cell
            );
          }
        } else if (!visibleIndex) {
          if (
            this.gridColumnHeadingCells[colIndex].cell.parentElement !=
            this.gridRowHeading.rowEl
          ) {
            freezeColCellsFragment.appendChild(
              this.gridColumnHeadingCells[colIndex].cell
            );
            appendCells = true;
          }
          // filter cells
          if (
            this.gridFilterCells[colIndex].cell.parentElement !=
            this.gridRowFilters.rowEl
          ) {
            this.gridRowFilters.rowEl.appendChild(
              this.gridFilterCells[colIndex].cell
            );
            // appendCells = true;
          }
        }

        gridCell.update(this.forceUpdate, true);
        // let appendTo: any;

        // check if cell is in correct element
        if (colIndex < this.freezeCol && rowIndex < this.freezeRow) {
          // corner row
          if (gridCell.cell.parentElement != gridCornerRow.rowEl) {
            // appendTo = gridCornerRow.rowEl;
            gridCornerRow.rowEl.appendChild(gridCell.cell);
            gridCell.sticky = true;
            appendCells = true;
          }
        } else if (colIndex < this.freezeCol) {
          // column row
          if (gridCell.cell.parentElement != gridColRow.rowEl) {
            // appendTo = freezeRowCellsFragment;
            freezeRowCellsFragment.appendChild(gridCell.cell);
            gridCell.sticky = true;
            appendCells = true;
          }
        } else {
          // main row
          if (gridCell.cell.parentElement != gridRow.rowEl) {
            // appendTo = cellsFragment;
            cellsFragment.appendChild(gridCell.cell);
            gridCell.sticky = false;
            appendCells = true;
          }
        }
        // gridCell.setFreeze(colIndex < this.freezeCol);
        // gridCell.update(this.forceUpdate);
        // if (appendTo) {
        //   appendTo.appendChild(gridCell.cell);
        // }
        gridCell.setSelected(this.selection);
        gridCell.rowIndex = visibleIndex;
      }
      if (appendCells) {
        gridRow.rowEl.insertBefore(cellsFragment, gridRow.rowEl.firstChild);
        gridColRow.rowEl.appendChild(freezeRowCellsFragment);
        this.gridRowHeading.rowEl.insertBefore(
          freezeColCellsFragment,
          this.gridRowHeading.rowEl.firstChild
        );
        // gridColRow.rowEl.appendChild(stickyCornerCellsFragment);
      }
      // this.rows[rowIndex].setFreeze(this.rows[rowIndex].row < this.freezeRow);
    });

    if (appendRows) {
      if (freezeRowFragment.childNodes.length)
        this.freezeRowEl.appendChild(freezeRowFragment);
      if (freezeCornerFragment.childNodes.length)
        this.freezeCornerEl.appendChild(freezeCornerFragment);
      // if (freezeCornerCellsFragment.childNodes.length) this.freezeCornerEl.appendChild(freezeCornerCellsFragment);
      if (freezeColumnFragment.childNodes.length)
        this.freezeColEl.appendChild(freezeColumnFragment);
      if (mainFragment.childNodes.length) this.mainEl.appendChild(mainFragment);
    }

    // remove any hidden rows
    this.Page.hiddenRows.forEach((rowIndex) => {
      if (this.rows[rowIndex] && this.rows[rowIndex].rowEl.parentElement) {
        this.rows[rowIndex].rowEl.parentElement!.removeChild(
          this.rows[rowIndex].rowEl
        );
      }
      if (
        this.colRows[rowIndex] &&
        this.colRows[rowIndex].rowEl.parentElement
      ) {
        this.colRows[rowIndex].rowEl.parentElement!.removeChild(
          this.colRows[rowIndex].rowEl
        );
      }
    });

    // show/hide rows
    this.visibleRows = visible;
    this.forceUpdate = false;
    this.rendering = false;
  };
  private resizeTimer: any;
  private onResize = () => {
    if (this.resizeTimer) {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = null;
    }
    this.resizeTimer = setTimeout(() => {
      this.updateElSizes();
      this.applyScale();
      this.forceUpdate = true;
      requestAnimationFrame(this.render);
    }, 300);
  };
  private onKeydown = (evt: KeyboardEvent) => {
    console.log(evt.key);

    this.selection.keyboard = false;

    if (this.disallowSelection) {
      return;
    }

    // esc
    if (evt.key === "Escape") {
      this.defocus();
      return;
    }

    // ctrl is down
    if (evt.key === "Control") {
      this.ctrlDown = true;
      return;
    }

    // shift
    if (evt.key === "Shift") {
      this.shiftDown = true;
    }

    // copy
    if (evt.ctrlKey && evt.key === "c" && this.isFocus) {
      this.Events.emit(this.Events.COPY, {
        html: this.getContentHtml(),
      });
      return;
    }

    // cut
    if (evt.ctrlKey && evt.key === "x" && this.isFocus && this.canEdit) {
      let html = this.getContentHtml();
      let success = this.emitDelete(evt);
      if (success) {
        this.Events.emit(this.Events.COPY, {
          html,
        });
      }
      return;
    }

    if (!this.hasFocus) {
      if (evt.key === "F2" && !evt.shiftKey) {
        if (!this.selection.from) {
          this.hasFocus = true;
          // this.mainElRect = this.mainEl.getBoundingClientRect();
          this.selection.from = this.gridCells[0][0];
          this.selection.to = this.gridCells[0][0];
          this.updateSelector();
          if (!this.keepSelectorInView()) requestAnimationFrame(this.render);
        }
      }
      return;
    }

    // ctrl navigation
    const moved = this.onCtrlNavigate(evt.key);
    if (moved) {
      evt.preventDefault();
      return;
    }

    if (["Home", "End"].indexOf(evt.key) > -1) {
      // evt.preventDefault();
      evt.preventDefault();
      if (this.editing) return;
      this.selectNextCell(evt.key.toLowerCase());
      return;
    }
    if (
      ["ArrowLeft", "ArrowUp", "ArrowRight", "ArrowDown"].indexOf(evt.key) > -1
    ) {
      let direction: string = evt.key.replace("Arrow", "").toLowerCase();
      if (this.buttonPopper) {
        this.selection.from!.selectOption(evt.key);
        evt.preventDefault();
      } else if (!this.editing) {
        this.selectNextCell(direction);
        evt.preventDefault();
      }
      return;
    }
    if (["PageUp", "PageDown"].indexOf(evt.key) > -1) {
      if (this.editing) return;
      let direction = evt.key.toLocaleLowerCase();
      // find cell that is out of view
      // let row = this.selection.from.dataset.row;
      this.selectNextCell(direction);
      evt.preventDefault();
    }
    if (evt.key === "Enter") {
      if (this.buttonPopper) {
        this.selection.from!.selectOption("Enter");
        return;
      }
      this.selectNextCell(this.shiftDown ? "up" : "down", evt.key);
      evt.preventDefault();
    }
    if (evt.key === "Tab") {
      if (this.buttonPopper) {
        this.selection.from!.selectOption("Enter");
        return;
      }
      this.selectNextCell(this.shiftDown ? "left" : "right", evt.key);
      evt.preventDefault();
    }
    if (!this.editing && this.selection.from) {
      if (evt.key === "Delete" && this.canEdit) {
        this.emitDelete(evt);
        return;
      }

      if (this.selection.from !== this.selection.to) {
        return;
      }

      if (evt.code === "Space") {
        if (
          this.selection.from.button &&
          this.selection.from.button.type === "checkbox"
        ) {
          this.rowChecked = true;
          this.selection.keyboard = true;
          // this.selection.from.toggleCheckbox();
          this.editCell(this.selection.from.row, this.selection.from.col);
        }
        evt.preventDefault();
        return;
      }

      if (evt.key === "F2" && !evt.shiftKey) {
        if (this.selection.from === this.selection.to) {
          this.editCell(this.selection.from.row, this.selection.from.col);
        }
        return;
      }
      if (!evt.ctrlKey && evt.key.length === 1) {
        if (!this.selection.from.hasButtonOptions) this.keyValue = evt.key;
        this.editCell(this.selection.from.row, this.selection.from.col);
      }
    }
  };
  private onKeyup = (evt: KeyboardEvent) => {
    if (evt.key === "Control") {
      this.ctrlDown = false;
    }
    if (evt.key === "Shift") {
      this.shiftDown = false;
    }
    if (this.selection.keyboard) {
      if (evt.key === "Shift") {
        this.selection.from = this.getGridCell(
          this.selection.rowFrom,
          this.selection.colFrom
        )!;
        this.selection.to = this.getGridCell(
          this.selection.rowTo,
          this.selection.colTo
        )!;
      }
      // KEEP this.emitSelected(evt, this.selection.type);
      this.emitCellSelected(evt);
      this.selection.keyboard = false;
    }
    if (!this.shiftDown) {
      this.selection.keyboard = false;
    }
  };
  private onMouseDown = (evt: any) => {
    // NEED?
    let isPopper =
      this.buttonPopper &&
      helpers.isTarget(this.buttonPopper.popper, evt.target);
    this.isFocus = this.hasFocus =
      helpers.isTarget(this.pageEl, evt.target) || isPopper;
    if (!this.hasFocus) {
      this.defocus(true);
      this.Events.emit(this.Events.BLUR);
      return;
    }

    if (evt.touches) {
      if (evt.touches.length == 2) {
        this.pinchDistance = this.getTouchDistance(evt.touches);
        this.pinchScale = this.scale;
        this.clearSelector();
        return;
      }
      evt.clientX = evt.touches[0].pageX;
      evt.clientY = evt.touches[0].pageY;
    }

    // check if input
    if (evt.target.classList && evt.target.classList.contains("grid-input")) {
      return;
    }

    let cell: any = this.getGridCellTarget(evt.target);

    if (cell) {
      this.Events.emit(this.Events.CELL, {
        cell: this.gridCells[cell.dataset.row][cell.dataset.col],
        evt,
      });
    } else if (this.buttonPopper) {
      // check for button dropdown
      if (!isPopper) this.defocus();
      // this.Events.emit(this.Events.NO_CELL);
    } else {
      this.defocus();
    }
  };
  private onMouseMove = (evt: any) => {
    if (evt.touches) {
      if (evt.touches.length >= 2) {
        let distance = this.getTouchDistance(evt.touches);
        // console.log(distance / this.pinchDistance);
        // evt.preventDefault();
        // evt.stopPropagation();
        this.applyScale((this.pinchScale * distance) / this.pinchDistance);
        // this.updateSelector();
        // this.applySortIndicator();
        // this.render();
        requestAnimationFrame(this.render);
      }
      return;
    }
    if (this.selection.selected && !this.selection.inside) {
      let cell: any = this.getGridCellTarget(evt.target);
      if (cell)
        this.Events.emit(this.Events.CELL, {
          cell: this.gridCells[cell.dataset.row][cell.dataset.col],
          evt,
        });
    }
  };
  private onMouseUp = (evt: any) => {
    if (!this.hasFocus) return;

    // check if input
    if (evt.target.classList && evt.target.classList.contains("grid-input")) {
      return;
    }

    // check if button dropdown
    if (this.buttonPopper) {
      if (helpers.isTarget(this.buttonPopper.popper, evt.target)) {
        // this.defocus();
        return;
      }
    }

    let cellDiv: any = this.getGridCellTarget(evt.target);
    let cell = cellDiv
      ? this.gridCells[cellDiv.dataset.row][cellDiv.dataset.col]
      : this.selection.to;
    this.Events.emit(this.Events.CELL, {
      cell,
      evt,
    });

    // if (!helpers.isTarget(evt.target, this.pageEl)) this.hasFocus = false;
    // this.selection.selected = false;
  };
  private emitDelete(evt: any): boolean {
    // check if there are buttons
    let gridCells: IGridCells = this.getSelectedCells();
    let isButtonValue: boolean = false;
    gridCells.forEach((row) => {
      row.forEach((gridCell: IGridCell) => {
        if (gridCell.hasButtonOptions) isButtonValue = true;
      });
    });
    if (isButtonValue) {
      this.Events.emit(this.Events.ERROR, {
        message: "Cell range contains at least one button",
      });
      return false;
    }
    this.Events.emit(this.Events.DELETE, {
      selection: this.selection,
      event: evt,
    });
    return true;
  }
  private defocus(blur?: boolean): void {
    this.dirty = false;
    if (this.buttonPopper) {
      // this.cancelCellEdit(true);
      this.destroyPopper();
      this.hasFocus = false;
    } else if (this.editing) {
      this.editing = false;
      this.Events.emit(this.Events.CELL_RESET, this.selection.last);
    } else if (!blur) {
      this.hasFocus = false;
      this.selection.last = this.selection.from;
      if (this.selection.from) {
        this.Events.emit(this.Events.NO_CELL);
      }
      this.selection.from = undefined;
      this.selection.to = undefined;
      this.updateSelector();
      requestAnimationFrame(this.render);
    }
  }
  private getGridCellTarget(evtTarget: any): HTMLDivElement | null {
    let clickedEl: any = evtTarget;
    while (
      clickedEl &&
      clickedEl.className &&
      !clickedEl.classList.contains("grid-cell")
    ) {
      clickedEl = clickedEl.parentNode;
    }
    return clickedEl.classList.contains("grid-cell") &&
      !clickedEl.classList.contains("filter")
      ? clickedEl
      : null;
  }
  private onCtrlNavigate(which: string): boolean {
    if (
      this.editing ||
      [
        "Home",
        "End",
        "ArrowLeft",
        "ArrowRight",
        "ArrowDown",
        "ArrowUp",
        "a",
      ].indexOf(which) < 0 ||
      !this.ctrlDown ||
      !this.selection.from ||
      !this.selection.to ||
      this.disallowSelection
    ) {
      return false;
    }

    let rowFrom = this.selection.from.row;
    let colFrom = this.selection.from.col;
    let rowTo = this.selection.to.row;
    let colTo = this.selection.to.col;
    let update = "all";

    if (which === "a") {
      rowFrom = 0;
      colFrom = 0;
      rowTo = this.Page.content.length - 1;
      colTo = this.Page.content[0].length - 1;
      this.selection.type = "all";
    }
    if (which === "Home") {
      if (!this.shiftDown) {
        rowFrom = 0;
        colFrom = 0;
      }
      rowTo = 0;
      colTo = 0;
    }
    if (which === "End") {
      if (!this.shiftDown) {
        rowFrom = this.Page.content.length - 1;
        colFrom = this.Page.content[0].length - 1;
      }
      rowTo = this.Page.content.length - 1;
      colTo = this.Page.content[0].length - 1;
      // update = 'end';
    }

    let x = 0;
    let y = 0;
    // look for next empty cell value
    let lookForEmptyValue = true;
    let refCell =
      this.selection.to.dataset.col > this.selection.from.dataset.col
        ? this.selection.to
        : this.selection.from;
    if (which === "ArrowRight") {
      let refCell =
        this.selection.to.col > this.selection.from.col
          ? this.selection.to
          : this.selection.from;
      let cell: IGridPageContentCell = this.Page.content[refCell.rowIndex][
        refCell.col
      ];
      lookForEmptyValue = cell.value ? true : false;
      for (
        let colIndex = refCell.col + 1;
        colIndex < this.Page.content[0].length;
        colIndex++
      ) {
        let cell: IGridPageContentCell = this.Page.content[refCell.rowIndex][
          colIndex
        ];
        // let hidden = this.state.hiddenColumns.indexOf(cell.index.col) > -1;
        // if (hidden) continue;
        if (colIndex === refCell.col + 1 && !cell.value) {
          lookForEmptyValue = false;
          continue;
        }
        if (!cell.value && lookForEmptyValue) {
          x = colIndex - 1;
          break;
        }
        if (cell.value && !lookForEmptyValue) {
          x = colIndex;
          break;
        }
      }
      if (!x) {
        x = this.Page.content[0].length - 1;
      }

      colTo = x;
      if (!this.shiftDown) {
        colFrom = x;
      }
    } else if (which === "ArrowLeft") {
      let refCell =
        this.selection.to.col > this.selection.from.col
          ? this.selection.from
          : this.selection.to;
      let cell: IGridPageContentCell = this.Page.content[refCell.rowIndex][
        refCell.col
      ];
      lookForEmptyValue = cell.value ? true : false;
      for (let colIndex = refCell.col - 1; colIndex >= 0; colIndex--) {
        let cell: IGridPageContentCell = this.Page.content[refCell.rowIndex][
          colIndex
        ];
        if (colIndex === refCell.col - 1 && !cell.value) {
          lookForEmptyValue = false;
          continue;
        }
        if (!cell.value && lookForEmptyValue) {
          x = colIndex + 1;
          break;
        }
        if (cell.value && !lookForEmptyValue) {
          x = colIndex;
          break;
        }
      }
      colTo = x;
      if (!this.shiftDown) {
        colFrom = x;
      }
    } else if (which === "ArrowDown") {
      let refCell =
        this.selection.to.rowIndex > this.selection.from.rowIndex
          ? this.selection.to
          : this.selection.from;
      let cell: IGridPageContentCell = this.Page.content[refCell.rowIndex][
        refCell.col
      ];
      lookForEmptyValue = cell.value ? true : false;
      for (
        let rowIndex = refCell.rowIndex + 1;
        rowIndex < this.Page.content.length;
        rowIndex++
      ) {
        let cell: IGridPageContentCell = this.Page.content[rowIndex][
          refCell.col
        ];
        if (rowIndex === refCell.rowIndex + 1 && !cell.value) {
          lookForEmptyValue = false;
          continue;
        }
        if (!cell.value && lookForEmptyValue) {
          y = rowIndex - 1;
          break;
        }
        if (cell.value && !lookForEmptyValue) {
          y = rowIndex;
          break;
        }
      }
      if (!y) {
        y = this.Page.content.length - 1;
      }
      rowTo = y;
      if (!this.shiftDown) {
        rowFrom = y;
      }
    } else if (which === "ArrowUp") {
      let refCell =
        this.selection.to.rowIndex > this.selection.from.rowIndex
          ? this.selection.from
          : this.selection.to;
      let cell: IGridPageContentCell = this.Page.content[refCell.rowIndex][
        refCell.col
      ];
      lookForEmptyValue = cell.value ? true : false;
      for (let rowIndex = refCell.rowIndex - 1; rowIndex >= 0; rowIndex--) {
        let cell: IGridPageContentCell = this.Page.content[rowIndex][
          refCell.col
        ];
        if (rowIndex === refCell.rowIndex - 1 && !cell.value) {
          lookForEmptyValue = false;
          continue;
        }
        if (!cell.value && lookForEmptyValue) {
          y = rowIndex + 1;
          break;
        }
        if (cell.value && !lookForEmptyValue) {
          y = rowIndex;
          break;
        }
      }
      rowTo = y;
      if (!this.shiftDown) {
        rowFrom = y;
      }
    }
    const cell = this.Page.content[rowFrom][colFrom];
    this.selection.from = this.getGridCell(
      cell.index.row,
      cell.index.col
    ) as IGridCell;
    const cellTo = this.Page.content[rowTo][colTo];
    this.selection.to = this.getGridCell(
      cellTo.index.row,
      cellTo.index.col
    ) as IGridCell;
    this.selection.keyboard = true;
    this.updateSelector();
    if (!this.keepSelectorInView()) requestAnimationFrame(this.render);

    return true;
  }
  private selectNextCell(direction: string, key: string = ""): void {
    if (!this.selection.from || !this.selection.to) return;
    let which: string =
      this.shiftDown && key !== "Enter" && key !== "Tab" ? "to" : "from";
    let row = this.selection[which].rowIndex;
    let col = this.selection[which].col;
    let set: string = "";
    let scrollTop = this.mainEl.scrollTop;
    let scrollLeft = this.mainEl.scrollLeft;
    let scrollBarWidth =
      this.Page.height > this.containerEl.offsetHeight
        ? this.Page.scrollbarWidth
        : 0;
    let scrollBarHeight =
      this.Page.width > this.containerEl.offsetWidth
        ? this.Page.scrollbarWidth
        : 0;
    if (direction === "pagedown") {
      let visibleRow = -1;
      let yPos = 0;
      this.visibleRows
        .concat()
        .reverse()
        .forEach((rowIndex) => {
          if (visibleRow > -1) return;
          yPos = this.Page.rowPos(rowIndex);
          if (
            yPos <
            this.mainEl.scrollTop +
              this.containerEl.offsetHeight +
              this.Page.scrollbarWidth
          ) {
            visibleRow = rowIndex;
          }
        });
      if (visibleRow > -1) {
        scrollTop = yPos - this.freezeRowTop;
        row = visibleRow;
      }
    }
    if (direction === "pageup") {
      let visibleRow = -1;
      let yPos = 0;
      this.visibleRows.forEach((rowIndex) => {
        if (visibleRow > -1) return;
        yPos = this.Page.rowPos(rowIndex);
        if (yPos >= this.mainEl.scrollTop + this.freezeRowTop) {
          visibleRow = rowIndex;
        }
      });
      if (visibleRow > -1) {
        scrollTop =
          yPos -
          this.containerEl.offsetHeight +
          scrollBarHeight +
          this.Page.rowHeights[row];
        row = visibleRow;
      }
    }
    if (direction === "home") {
      scrollLeft = 0;
      col = 0;
      set = "col";
    }
    if (direction === "end") {
      col = this.Page.content[0].length - 1;
      scrollLeft = this.Page.colPos(col);
      set = "col";
    }
    if (direction === "up") {
      row--;
      // this.Page.hiddenRows
      //   .concat()
      //   .reverse()
      //   .forEach((index) => {
      //     if (index === row) row--;
      //   });      
      if (row < 0) row = 0;
      scrollTop = this.getScrollTop(row);
      // let yPos = this.Page.rowPos(row);
      // if (yPos - this.mainEl.scrollTop < this.freezeRowTop) {
      //   scrollTop = yPos - this.freezeRowTop;
      // }
    }
    if (direction === "down") {
      row++;
      if (!this.Page.content[row]) {
        row = this.Page.content.length - 1;
      }
      // this.Page.hiddenRows.forEach((index) => {
      //   if (index === row) row++;
      // });      
      // let yPos = this.Page.rowPos(row);
      scrollTop = this.getScrollTop(row);
      // console.log(st);
      // if (st > -1) scrollTop = st;
      // if (
      //   yPos - this.mainEl.scrollTop + this.Page.rowHeights[row] + scrollBarHeight >
      //   this.containerEl.offsetHeight
      // ) {
      //   console.log('move');
      //   scrollTop = yPos - this.containerEl.offsetHeight + scrollBarHeight + this.Page.rowHeights[row];
      // }
    }
    if (direction === "right") {
      col++;
      if (!this.Page.content[row][col]) {
        col = this.Page.content[0].length - 1;
      }
      this.Page.hiddenColumns.forEach((colIndex) => {
        if (colIndex === col) col++;
      });
      scrollLeft = this.getScrollLeft(col);
      // let xPos = this.Page.colPos(col);
      // if (
      //   xPos - this.mainEl.scrollLeft + this.Page.colWidths[col] + scrollBarWidth >
      //   this.containerEl.offsetWidth
      // ) {
      //   scrollLeft = xPos - this.containerEl.offsetWidth + scrollBarWidth + this.Page.colWidths[col];
      // }
    }
    if (direction === "left") {
      col--;
      this.Page.hiddenColumns
        .concat()
        .reverse()
        .forEach((colIndex) => {
          if (colIndex === col) col--;
        });
      if (col < 0) col = 0;
      scrollLeft = this.getScrollLeft(col);
      // let xPos = this.Page.colPos(col);
      // if (xPos - this.mainEl.scrollLeft < this.freezeColLeft) {
      //   scrollLeft = xPos - this.freezeColLeft;
      // }
    }
    let cell = this.Page.content[row][col];
    this.selection[which] = this.gridCells[cell.index.row][cell.index.col];
    if (!this.shiftDown || key === "Enter" || key === "Tab") {
      this.selection.to = this.selection.from;
    }
    this.selection.keyboard = true;
    this.updateSelector();
    let render = false;
    if (
      scrollLeft === this.mainEl.scrollLeft ||
      (scrollLeft <= 0 && !this.mainEl.scrollLeft) ||
      (scrollLeft > this.mainEl.scrollLeft &&
        this.mainEl.scrollLeft >=
          this.mainEl.scrollWidth - this.mainEl.offsetWidth)
    ) {
      render = true;
    } else {
      this.mainEl.scrollLeft = scrollLeft;
      console.log("scroll", "left", scrollLeft);
    }
    if (
      scrollTop === this.mainEl.scrollTop ||
      (scrollTop <= 0 && !this.mainEl.scrollTop) ||
      (scrollTop > this.mainEl.scrollTop &&
        this.mainEl.scrollTop >=
          this.mainEl.scrollHeight - this.mainEl.offsetHeight)
    ) {
      render = true;
    } else {
      this.mainEl.scrollTop = scrollTop;
      console.log("scroll", "top", scrollTop);
    }
    if (render) requestAnimationFrame(this.render);
    // this.updateSelector();
    // KEEP this.cancelCellEdit();
  }
  private emitCellSelected(evt: any): void {
    this.Events.emitCellSelected(this.getSelection(evt));
  }
  private getScrollTop(row: number): number {
    // scrollTop = yPos - this.freezeRowTop;
    let scrollTop = this.mainEl.scrollTop;
    let yPos = this.Page.rowPos(row);
    let rowHeight = this.Page.rowHeights[row];
    if (this.headings) yPos += this.Page.headingsHeight;
    let freezeRowTop = this.freezeRowTop;

    if (
      yPos - scrollTop + rowHeight + this.Page.scrollbarHeight >
      this.containerEl.offsetHeight
    ) {
      scrollTop =
        yPos -
        this.containerEl.offsetHeight +
        this.Page.scrollbarHeight +
        rowHeight;
    } else if (yPos - scrollTop < freezeRowTop) {
      scrollTop = yPos - freezeRowTop;
    }
    return scrollTop;
  }
  private getScrollLeft(col: number): number {
    let scrollLeft = this.mainEl.scrollLeft;
    let xPos = this.Page.colPos(col);
    if (this.headings) {
      // TODO strange issue when scrolling left
      if (col > this.freezeCol) xPos += this.Page.headingsWidth;
    }
    // xPos *= this.scale;
    let freezeColLeft = this.freezeColLeft;
    let colWidth = this.Page.colWidths[col];
    if (
      xPos - this.mainEl.scrollLeft + colWidth + this.Page.scrollbarWidth >
      this.containerEl.offsetWidth
    ) {
      scrollLeft =
        xPos -
        this.containerEl.offsetWidth +
        this.Page.scrollbarWidth +
        colWidth;
    } else if (xPos - this.mainEl.scrollLeft < freezeColLeft) {
      scrollLeft = xPos - freezeColLeft;
    }
    return scrollLeft;
  }
  private getTouchDistance(touches: any = []): number {
    let x = touches[1].clientX - touches[0].clientX;
    let y = touches[1].clientY - touches[0].clientY;
    return Math.abs(Math.sqrt(x * x + y * y));
  }
  private isRightClick(evt: any): boolean {
    let isRightMB = false;
    if ("which" in evt)
      // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
      isRightMB = evt.which === 3;
    else if ("button" in evt)
      // IE, Opera
      isRightMB = evt.button === 2;
    return isRightMB;
  }

  private destroyPopper(): void {
    if (this.buttonPopper) {
      if (this.popperParent)
        this.popperParent.removeChild(this.buttonPopper.popper);
      else this.pageEl.removeChild(this.buttonPopper.popper);
      this.buttonPopper.destroy();
      this.buttonPopper = null;
    }
  }

  // tracking
  public clearTrackingData(): void {
    this.trackingData = [];
    for (let rowIndex = 0; rowIndex < this.gridCells.length; rowIndex++) {
      for (
        let colIndex = 0;
        colIndex < this.gridCells[rowIndex].length;
        colIndex++
      ) {
        let cell: IGridCell | null = this.getGridCell(rowIndex, colIndex, true);
        if (cell) cell.history(false);
      }
    }
  }
  public updateTrackingData(trackingData: ITrackingData[]): void {
    this.trackingData = trackingData;
    this.updateHistoryCells();
  }
  public clearColumnFilters(): void {
    this.gridFilterCells.forEach((cell) => {
      cell.clear();
    });
    let filters: IGridFilter[] = [];
    this.state.filters.forEach((filter) => {
      if (filter.name && filter.name.indexOf("grid-") > -1) return;
      filters.push(filter);
    });
    this.state.filters = filters;
    this.updateState(this.state);
  }
  private updateHistoryCells(): void {
    if (!this.tracking || !this.trackingData || this.trackingData.length < 2)
      return;
    for (let i = 0; i < this.trackingData.length; i++) {
      if (!i) continue;
      for (
        let rowIndex = 0;
        rowIndex < this.trackingData[i].content_diff.length;
        rowIndex++
      ) {
        if (!this.trackingData[i].content_diff[rowIndex]) continue;
        for (
          let colIndex = 0;
          colIndex < this.trackingData[i].content_diff[rowIndex].length;
          colIndex++
        ) {
          if (!this.trackingData[i].content_diff[rowIndex][colIndex]) continue;
          let cell: IGridCell | null = this.getGridCell(rowIndex, colIndex);
          if (!cell) continue;
          cell.history(
            true,
            this.getTrackingUser(this.trackingData[i].modified_by.id)
          );
        }
      }
    }
  }
  private getTrackingUser(userId: number): number {
    let index: number = this.trackingUsers.indexOf(userId);
    if (index > -1) {
      return index;
    } else {
      this.trackingUsers.push(userId);
      return this.trackingUsers.length - 1;
    }
  }
  private applyScale(n?: number): void {
    this.pageElRect = this.pageEl.getBoundingClientRect();
    // this.wrapperElRect = this.wrapperEl.getBoundingClientRect();

    this.mainEl.classList.remove("overflow-x", "overflow-y");

    let cellsOffsetX = this.headings ? this.Page.headingsWidth : 0;
    let cellsOffsetY = this.headings ? this.Page.headingsHeight : 0;
    let scale = 1;
    // const borderWidths: IGridCellBorderWidths = this.gridCells[0][this.gridCells.length-1].getBorderWidths();

    const fitWidth = (contain?: boolean) => {
      // check for border
      let scale =
        this.pageElRect.width /
        (this.Page.width + this.Page.borderRightWidth + cellsOffsetX);
      if (contain) return scale;
      let height = this.Page.height * scale;
      if (height > this.pageElRect.height) {
        scale =
          (this.pageElRect.width - this.Page.scrollbarSize * scale) /
          (this.Page.width + this.Page.borderRightWidth + cellsOffsetX);
      }
      return scale;
    };
    const fitHeight = (contain?: boolean) => {
      let scale =
        this.pageElRect.height /
        (this.Page.height + this.Page.borderBottomWidth + cellsOffsetY);
      if (contain) return scale;
      let width = this.Page.width * scale;
      if (width > this.pageElRect.width) {
        scale =
          (this.pageElRect.height - this.Page.scrollbarSize * scale) /
          (this.Page.height + this.Page.borderBottomWidth + cellsOffsetY);
      }
      return scale;
    };

    if (n) {
      if (n > 5) n = 5;
      if (n < 0.2) n = 0.2;
      this.scale = n;
      console.log("scale", n);
    } else if (this.fit === "width") {
      this.mainEl.classList.add("overflow-x");
      this.scale = fitWidth();
    } else if (this.fit === "height") {
      this.mainEl.classList.add("overflow-y");
      this.scale = fitHeight();
    } else if (this.fit === "contain") {
      let scaleWidth = fitWidth(true);
      let scaleHeight = fitHeight(true);
      this.mainEl.classList.add("overflow-y", "overflow-x");
      this.scale = scaleHeight < scaleWidth ? scaleHeight : scaleWidth;
    } else {
      this.scale = 1;
    }
    this.Page.scale = this.scale;

    if (this.scale == 1) {
      this.containerEl.style.removeProperty("transform");
      this.containerEl.style.removeProperty("width");
      this.containerEl.style.removeProperty("height");
      // this.mainEl.style.removeProperty('transform');
      // this.mainEl.style.removeProperty('top');
      // this.freezeCornerEl.style.removeProperty('transform');
      // this.freezeRowEl.style.removeProperty('transform');
      // this.freezeColEl.style.removeProperty('transform');
      // this.selectorEl.style.removeProperty('transform');
      // this.wrapperEl.style.removeProperty('width');
      // this.containerEl.style.removeProperty('height');
    } else {
      let width = this.headings
        ? this.Page.width + this.Page.headingsWidth
        : this.Page.width;
      let height = this.headings
        ? this.Page.height + this.Page.headingsHeight
        : this.Page.height;
      this.containerEl.style.setProperty("transform", `scale(${this.scale})`);
      this.containerEl.style.setProperty(
        "width",
        `${this.pageElRect.width / this.scale}px`
      );
      this.containerEl.style.setProperty(
        "height",
        `${this.pageElRect.height / this.scale}px`
      );
      // this.mainEl.style.setProperty('transform', `scale(${this.scale})`);
      // if (this.headings) {
      //   this.mainEl.style.setProperty('top', `${20 * this.scale}px`);
      // }
      // // this.wrapperEl.style.setProperty('width', `${this.pageElRect.width / this.scale}px`);
      // this.freezeCornerEl.style.setProperty('transform', `scale(${this.scale})`);
      // this.freezeRowEl.style.setProperty('transform', `scale(${this.scale})`);
      // this.freezeColEl.style.setProperty('transform', `scale(${this.scale})`);
      // this.selectorEl.style.setProperty('transform', `scale(${this.scale})`);
      // // this.stickyEl.style.setProperty('width', `${this.pageElRect.width / this.scale}px`);
      // // this.wrapperEl.style.setProperty('height', `${this.pageElRect.height / this.scale}px`);

      // width *= this.scale;
      // height *= this.scale;
      // this.boxEl.style.setProperty('width', `${width}px`);
      // this.boxEl.style.setProperty('height', `${height}px`);
    }

    // if (this.ps) this.ps.update(this.fit, this.scale);
  }
  private applyFilterValues(): void {
    this.gridFilterCells.forEach((cell, colIndex) => {
      cell.clear();
      let filter = this.state.filters.find((f) => {
        return f.col == colIndex;
      });
      if (!filter) return;
      cell.setValue(filter);
    });
  }
  public getDefaultState(): IGridState {
    return {
      hiddenColumns: [],
      filters: [],
      sorting: {
        enabled: false,
        type: IGridFieldType.STRING,
        direction: IGridSortDirection.ASC,
        col: 0,
        field: "",
      },
    };
  }
}
