export interface IActionsStyle {
  ref: string;
  range: string;
  conditions: IActionsStyleCondition[];
  default_style: IActionsStyleConditionStyle;
  default_columns: any,
  [key: string]: any;
  parse(data: any): void;
}

export interface IActionsStyleCondition {
  exp: string;
  style: IActionsStyleConditionStyle;
  columns: any[];
  [key: string]: any;
  parse(data: any): void;
}

export interface IActionsStyleConditionStyle {
  color: string;
  background: string;
}

export class Style implements IActionsStyle {
  public ref: string = '';
  public range: string = '';
  public conditions: IActionsStyleCondition[] = [];
  public default_style: IActionsStyleConditionStyle = {
    color: '',
    background: ''
  }
  public default_columns: any = ['C'];
  constructor(data?: any) {
    this.parse(data || {});
  }
  public parse(data: any): void {
    ['ref', 'range', 'conditions', 'default_style', 'default_columns'].forEach(key => {
      if (!data[key]) return;
      (<IActionsStyle>this)[key] = data[key];
    });
  }
}
export class StyleCondition implements IActionsStyleCondition {
  public exp: string = '';
  public style: IActionsStyleConditionStyle = {
    color: '#000000',
    background: '#FFFFFF',

  };
  public columns: any[] = ['C'];
  constructor(data?: any) {
    this.parse(data || {});
  }
  public parse(data: any): void {
    ['exp', 'style', 'columns'].forEach(key => {
      if (!data[key]) return;
      (<IActionsStyleCondition>this)[key] = data[key];
    });
  }
}
