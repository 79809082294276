import merge from 'deepmerge';

export interface IActionsChartOptionsXAxis {
  categories: string[];
}

export interface IActionsChartOptionsPlotBar {
  horizontal: boolean;
}

export interface IActionsChartOptionsPlot {
  bar?: IActionsChartOptionsPlotBar;
}
export interface IActionsChartOptionsLegend {
  show?: boolean;
}

export interface IActionsChartOptions {
  xaxis: IActionsChartOptionsXAxis;
  plotOptions?: IActionsChartOptionsPlot;
  legend?: IActionsChartOptionsLegend;
}

// export interface IActionsChartSeries extends Array<any> {
//     [index: number]: string;
//   }

export interface IActionsChart {
  name: string;
  type: string;
  options: IActionsChartOptions;
  range: string;
  series: string[];
  [key: string]: any;
}

export class Chart implements IActionsChart {
  public name: string = '';
  public type: string = 'bar';
  public range: string = '';
  public options: IActionsChartOptions = {
    plotOptions: {
      bar: {
        horizontal: false
      }
    },
    xaxis: {
      categories: []
    },
    legend: {
      show: false,
    }
  };
  public series: any[] = [];
  //   public series = [];
  constructor(data?: any) {
    this.parse(data || {});
  }
  public parse(data: any): void {
    ['name', 'type', 'options', 'range', 'series'].forEach(key => {
      if (!data[key]) return;
      (<IActionsChart>this)[key] = ['options'].includes(key) ? merge(this.options, data[key]) : data[key];
    });
  }
}
