import { IStorageService } from './Storage';
import { IEncryptionKey } from './Crypto';
import merge from 'deepmerge';

export interface IKeysService {
  saveKey(domainName: string, key: any): void;
  getKey(domainName: string, name: string): IEncryptionKey;
  updateKeys(): void;
  load(): void;
  removeKey(domain: string, keyName: string): void;
  mergeData(data: any): boolean;
  reset(): void
}

class KeysService {
  private data: any = {};
  private cookieName: string = 'encryption_keys';

  constructor(public storage: IStorageService) {
    // this.data = storage.global.get(this.cookieName, {});
    this.load();
  }

  public saveKey(domainName: string, key: any): void {
    if (!this.data[domainName]) {
      this.data[domainName] = {};
    }
    this.data[domainName][key.name] = key.passphrase;
    this.updateKeys();
  }

  public getKey(domainName: string, name: string): IEncryptionKey {
    // console.log('getKey', this.data);
    this.load();
    if (!this.data[domainName] || !this.data[domainName][name]) {
      return {
        name: '',
        passphrase: ''
      };
    }
    return {
      name: name,
      passphrase: this.data[domainName][name]
    };
  }

  public updateKeys(): void {
    let data: any = this.storage.global.get(this.cookieName, {});
    data = Object.assign(data, this.data);
    this.storage.global.create(this.cookieName, JSON.stringify(data));
    console.log('updateKeys', data);
  }

  public getKeys(domainName?: string): any {
    if (!this.data) {
      return {};
    }

    if (typeof domainName !== 'undefined') {
      return this.data[domainName] || {};
    }

    return this.data;
  }

  public load(): void {
    this.data = this.storage.global.get(this.cookieName, {});
  }

  public removeKey(domain: string, keyName: string): void {
    if (!this.data[domain] || !this.data[domain][keyName]) {
      return;
    }
    delete this.data[domain][keyName];
    this.updateKeys();
  }

  public mergeData(data: any): boolean {
    this.load();
    if (!Object.keys(data).length) return false;
    Object.keys(data).forEach((domain: any) => {
      this.data[domain] = merge(this.data[domain], data[domain]);
    });
    this.updateKeys();
    return true;
  }

  public reset(): void {
    this.storage.global.remove(this.cookieName);
    this.load();
  }
}

export default KeysService;
