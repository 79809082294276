import Emitter, { IEmitter } from '../Emitter';
import { IPageContentCell } from '../Page/Content';
import { IGridSelection, IGridCells } from './Grid';
import { IGridCell, IGridCellDetails } from './GridCell';

export interface IGridEvents extends IEmitter {
  CELL: string;
  BLUR: string;
  NO_CELL: string;
  CELL_LINK_CLICKED: string;
  CELL_DOUBLE_CLICKED: string;
  CELL_CLICKED: string;
  CELL_VALUE: string;
  CELL_SELECTED: string;
  CELL_RESET: string;
  CELL_CHECKED: string;
  CELL_OPTION: string;
  COPY: string;
  EDITING: string;
  RESIZE: string;
  RESIZING: string;
  RESIZED: string;
  COLUMNS_MOVED: string;
  DELETE: string;
  SELECTED_ALL: string;
  ERROR: string;
  COLUMN_FILTER: string;
  COLUMN_FILTER_FOCUS: string;
  emitCellSelected(data: IGridEventCellSelected): void;
}

class GridEvents extends Emitter implements IGridEvents {
  constructor() {
    super();
  }
  public get CELL_CLICKED(): string {
    return 'cell_clicked';
  }
  public get BLUR(): string {
    return 'blur';
  }
  public get NO_CELL(): string {
    return 'no_cell';
  }
  public get CELL_HISTORY_CLICKED(): string {
    return 'cell_history_clicked';
  }
  public get CELL_LINK_CLICKED(): string {
    return 'cell_link_clicked';
  }
  public get CELL_DOUBLE_CLICKED(): string {
    return 'cell_double_clicked';
  }
  public get CELL_VALUE(): string {
    return 'cell_value';
  }
  public get CELL_SELECTED(): string {
    return 'cell_selected';
  }
  public get CELL_RESET(): string {
    return 'cell_reset';
  }
  public get CELL_CHECKED(): string {
    return 'cell_checked';
  }
  public get CELL_OPTION(): string {
    return 'cell_option';
  }
  public get EDITING(): string {
    return 'editing';
  }
  public get RESIZE(): string {
    return 'resize';
  }
  public get RESIZING(): string {
    return 'resizing';
  }
  public get RESIZED(): string {
    return 'resized';
  }
  public get COLUMNS_MOVED(): string {
    return 'columns_moved';
  }
  public get COLUMN_FILTER(): string {
    return 'column_filter';
  }
  public get COLUMN_FILTER_FOCUS(): string {
    return 'column_filter_focus';
  }
  public get DELETE(): string {
    return 'delete';
  }
  public get SELECTED_ALL(): string {
    return 'selected_all';
  }
  public get CELL(): string {
    return 'cell';
  }
  public get COPY(): string {
    return 'copy';
  }
  public get INTERNAL_CELL_SELECTED(): string {
    return 'internal_cell';
  }
  public get ERROR(): string {
    return 'error';
  }
  public emitCellSelected(data: IGridEventCellSelected): void {
    this.emit(this.CELL_SELECTED, data);
  }
}

export interface IGridEventEditing {
  content: IPageContentCell;
  cell: IGridCell;
  editing: boolean;
  dirty: boolean;
}

export interface IGridEventCellClicked {
  cell: IGridCellDetails;
  content: IPageContentCell;
  event: MouseEvent;
  rightClick: boolean;
  historyClick: boolean;
  heading: boolean;
}

export interface IGridEventCellChecked {
  cell: IGridCellDetails;
  content?: IPageContentCell;
  checked: boolean;
  value: any;
}

export interface IGridEventCellSelection {
  from: IGridCellDetails;
  to: IGridCellDetails;
  heading: boolean;
  inside: boolean;
  reference: string;
  keyboard: boolean;
  type: string;
}

export interface IGridEventCellSelected {
  reference: string;
  selection: IGridEventCellSelection;
  rightClick: boolean;
  event: MouseEvent | KeyboardEvent | TouchEvent;
  which: string;
  count: number;
  multiple: boolean;
  colFrom?: number;
  colTo?: number;
  rowFrom?: number;
  rowTo?: number;
}

export interface IGridEventCopy {

}

export interface IGridEventDelete {
  cells: IGridCells;
  selection: IGridSelection;
  event: KeyboardEvent;
}

export interface IGridEventCellValue {
  cell: IGridCell;
  content: IPageContentCell;
  value: string | number | boolean;
}

export interface IGridEventResized {
  index: number;
  which: string;
  size: number;
}

export interface IGridEventCellChecked {
  cell: IGridCellDetails;
  content?: IPageContentCell;
  checked: boolean;
  value: any;
}

export interface IGridEventCellOption {
  cell: IGridCellDetails;
  content?: IPageContentCell;
  option: string;
}

export interface IGridEventError {
  message: string;
}

export default GridEvents;
