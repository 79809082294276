import GridPage, { IGridPage } from './GridPage';
import { IGridEvents } from './GridEvents';
import { filter } from 'bluebird';

export interface IGridRow {

  Page: IGridPage;
  Events: IGridEvents;    

  // params: IGridRowParams;
  // dataset: IGridRowDataset;
  rowEl: HTMLDivElement;
  // hidden: boolean;
  row: number;
  rowIndex: number;
  top: number;
  sticky: boolean;
  height: number;
  setHeight(size: number): void;
  // setOffet(offset: number): void;
  // setPosition(offset: number, move?: boolean): void;
  // update(row: number): void;
  setTop(freezeRowTop?: number): void;
  setIndex(index: number): void;
  // setHover(n: boolean): void;
  setFreeze(n: boolean): void;
}

export class GridRow implements IGridRow {
  // public Page!: IGridPage;
  // public Events!: IGridEvents;  
  public rowEl: HTMLDivElement;
  public sticky: boolean = false;
  public top: number = 0;
  public rowIndex: number = 0;
  public height: number = 0;
  constructor(public Page: IGridPage, public Events: IGridEvents, public row: number, public heading?: boolean, public filters?: boolean) {
    this.rowEl = document.createElement('div');
    this.rowEl.className = 'grid-row';
    if (heading) this.rowEl.classList.add('heading');
    else this.rowEl.classList.add('cells');
    if (filters) this.rowEl.classList.add('filter');
    // this.rowEl.setAttribute('id', `row-${row}`);
  //   this.update(row);
  // }
  // public update(row: number): void {
    this.row = row;
    this.rowIndex = row;
    // this.top = this.Page.rowPos(row);
    // this.rowEl.style.setProperty('top', `${this.top}px`);
    // this.rowEl.style.setProperty('transform', `translateY(${this.top}px)`);
    // this.rowEl.setAttribute('id', `row-${row}`);
    if (!heading && !filters) {
      let height = this.Page.originalContent[this.row][0].style.height!;
      this.height = parseFloat(`${height}`);
      this.rowEl.style.setProperty('height', height);
    } else {
      this.height = this.filters ? 30 : this.Page.headingsHeight;
    }
    // this.rowEl.style.setProperty('background-color', `#${this.Page.originalContent[this.row][0].style['background-color']}`);
    // this.rowEl.style.removeProperty('display');
    // this.rowEl.textContent = `${row}`;
    this.setIndex(row);
  }
  public setHeight(size: number): void {
    this.height = size;
    this.rowEl.style.setProperty('height', `${size}px`);
    this.Page.content[this.rowIndex][0].style.height = `${size}px`;
  }  
  public setTop(freezeRowTop?: number): void {
    this.top = freezeRowTop || this.Page.rowPos(this.rowIndex);
    this.rowEl.style.setProperty('top', `${this.top}px`);
  }
  public setIndex(index: number): void {
    this.rowIndex = index;
    // this.rowEl.textContent = `${index} - ${this.row}`;
    this.top = this.Page.rowPos(index);
    this.rowEl.style.setProperty('top', `${this.top}px`);
    let zIndex = this.Page.content.length - index;
    if (this.heading) zIndex++;
    this.rowEl.style.setProperty('z-index', `${zIndex}`);
    if (!this.heading) this.rowEl.style.setProperty('height', `${this.height}px`);
  }
  public setFreeze(n: boolean): void {
    if (this.sticky === n) return;
    // return;
    this.sticky = n;
    if (n) {
      // let top = this.Page.rowHeights.slice(0, this.row).reduce((a: any, b: any) => a + b, 0);
      // this.rowEl.classList.add('sticky');
      // this.rowEl.style.setProperty('top', `${this.top + this.Page.scrollTop}px`);
    } else {
      // this.rowEl.classList.remove('sticky');
      // this.rowEl.style.setProperty('top', `${this.top}px`);
    }
  }
}
