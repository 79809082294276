export interface IIPPConfig {
  api_url?: string;
  ws_url?: string;
  web_url?: string;
  app_url?: string;
  embed_url?: string;
  docs_url?: string;
  billing_url?: string;
  api_key: string;
  api_secret: string;
  transport?: string;
  storage_prefix?: string;
  cookie?: any;
  client_version?: any;
  uuid?: any;
  hsts?: boolean;
  maximum_pasted_cells?: number;
  pdfjs?: IConfigPDFJS;
  settings?: any;
  [key: string]: any;
}

export interface IConfigPDFJS {
  worker: string;
  load: string[];
}

export interface IConfig {
  set(config: IIPPConfig): void;
  api_url: string;
  ws_url: string;
  web_url: string;
  app_url: string;
  embed_url: string;
  docs_url: string;
  chat_url: string;
  help_url: string;
  auth_url: string;
  billing_url: string;
  api_key: string;
  api_secret: string;
  transport: string;
  storage_prefix: string;
  cookie: any;
  client_version: any;
  uuid: any;
  hsts: boolean;
  maximum_pasted_cells: number;
  pdfjs: IConfigPDFJS;
  settings: any;
}

export class Config implements IConfig {
  private _config: any = {
    api_url: 'https://www.ipushpull.com/api/1.0',
    ws_url: 'https://www.ipushpull.com',
    web_url: 'https://www.ipushpull.com',
    app_url: 'https://www.ipushpull.com/pages',
    embed_url: 'https://www.ipushpull.com/embed',
    auth_url: 'https://www.ipushpull.com/auth',
    docs_url: 'https://docs.ipushpull.com',
    billing_url: 'https://www.ipushpull.com/billing',
    chat_url: '',
    help_url: '',
    api_key: '',
    api_secret: '',
    transport: 'polling',
    storage_prefix: 'ipp',
    cookie: {
      oauth_access_token: 'access_token',
      ouath_refresh_token: 'refresh_token',
      uuid: 'uuid'
    },
    client_version: '',
    uuid: '',
    hsts: true,
    maximum_pasted_cells: 10000,
    settings: {}
  };

  public pdfjs: IConfigPDFJS = {
    worker: 'https://s3-eu-west-1.amazonaws.com/assets.ipushpull.com/js/pdfjs-dist/build/pdf.worker.min.js',
    load: [
      'https://s3-eu-west-1.amazonaws.com/assets.ipushpull.com/js/pdfjs-dist/web/compatibility.js',
      'https://s3-eu-west-1.amazonaws.com/assets.ipushpull.com/js/pdfjs-dist/build/pdf.min.js'
    ]
  };

  public set(config: IIPPConfig): void {
    if (!config) {
      return;
    }

    for (let prop in config) {
      if (config.hasOwnProperty(prop)) {
        this._config[prop] = config[prop];
      }
    }

    if (config.api_url && !config.ws_url) {
      let parts: string[] = config.api_url.split('/');
      this._config.ws_url = parts[0] + '//' + parts[2];
    }
  }

  public get api_url() {
    return this._config.api_url;
  }

  public get ws_url() {
    return this._config.ws_url;
  }

  public get web_url() {
    return this._config.web_url;
  }

  public get app_url() {
    return this._config.app_url;
  }

  public get embed_url() {
    return this._config.embed_url;
  }

  public get docs_url() {
    return this._config.docs_url;
  }

  public get chat_url() {
    return this._config.chat_url;
  }

  public get help_url() {
    return this._config.help_url;
  }

  public get billing_url() {
    return this._config.billing_url;
  }

  public get auth_url() {
    return this._config.auth_url;
  }

  public get api_key() {
    return this._config.api_key;
  }

  public get api_secret() {
    return this._config.api_secret;
  }

  public get transport() {
    return this._config.transport;
  }

  public get storage_prefix() {
    return this._config.storage_prefix;
  }

  public get cookie() {
    return this._config.cookie;
  }

  public get uuid() {
    return this._config.uuid;
  }

  public get client_version() {
    return this._config.client_version;
  }

  public get hsts() {
    return this._config.hsts;
  }

  public get maximum_pasted_cells() {
    return this._config.maximum_pasted_cells;
  }

  public get settings() {
    return this._config.settings;
  }

  public $get(): IIPPConfig {
    return this._config;
  }
}

// export default Config;
