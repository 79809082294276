import Helpers from '../Helpers';
import { IGridCell } from './GridCell';
import { IGridCells } from './Grid';

export interface IGridCanvas {
  canvas: HTMLCanvasElement;
  canvasContext: CanvasRenderingContext2D;
  bufferCanvas: HTMLCanvasElement;
  bufferCanvasContext: CanvasRenderingContext2D;
  gridCells: IGridCells;
  gridHeight: number;
  gridWidth: number;
  render(options: IGridImageOptions): string;
}

export interface IGridImageOptions {
  width: number;
  height: number;
  gridlines?: boolean;
  offsetX?: number;
  offsetY?: number;
  scale?: number;
  fit?: string;
  download?: boolean;
  downloadName?: string;
}

class GridCanvas implements IGridCanvas {
  public canvas!: HTMLCanvasElement;
  public canvasContext!: CanvasRenderingContext2D;
  public bufferCanvas!: HTMLCanvasElement;
  public bufferCanvasContext!: CanvasRenderingContext2D;
  public rectangles: any = [];
  public offsetRectangles: any = [];
  public texts: any = [];
  public lines: any = [];
  public hidden: any = [];
  public gridCells: IGridCells = [[]];
  public width: number = 0;
  public height: number = 0;
  public gridHeight: number = 0;
  public gridWidth: number = 0;
  public offsetX: number = 0;
  public offsetY: number = 0;
  public scale: number = 0;
  public gridlines: boolean = false;

  public borders: any = {
    widths: {
      none: 0,
      thin: 1,
      medium: 2,
      thick: 3
    },
    styles: {
      none: 'solid',
      solid: 'solid',
      double: 'double'
    },
    names: {
      t: 'top',
      r: 'right',
      b: 'bottom',
      l: 'left'
    }
  };

  public helpers: any;

  constructor(public container: HTMLDivElement) {
    this.helpers = new Helpers();
    this.canvas = document.createElement('canvas');
    let ctx: CanvasRenderingContext2D | null = this.canvas.getContext('2d');
    if (ctx) {
      this.canvasContext = ctx;
    }
    this.bufferCanvas = document.createElement('canvas');
    ctx = this.bufferCanvas.getContext('2d');
    if (ctx) {
      this.bufferCanvasContext = ctx;
    }
    this.canvas.style.setProperty('display', 'none');
    container.appendChild(this.canvas);
  }

  public render(options: IGridImageOptions): any {
    this.width = options.width;
    this.height = options.height;
    this.gridlines = options.gridlines === undefined ? false : options.gridlines;
    this.offsetX = options.offsetX === undefined ? 0 : options.offsetX;
    this.offsetY = options.offsetY === undefined ? 0 : options.offsetY;
    this.scale = options.scale === undefined ? 1 : options.scale;
    if (options.fit === 'width') {
      this.scale = this.width / this.gridWidth;
    } else if (options.fit === 'height') {
      this.scale = this.height / this.gridHeight;
    } else if (options.fit === 'contain') {
      let scaleWidth = this.width / this.gridWidth;
      let scaleHeight = this.height / this.gridHeight;
      this.scale = scaleHeight < scaleWidth ? scaleHeight : scaleWidth;
    }
    this.resetElements();

    // reset canvas
    this.clearCanvas();

    // draw rects
    this.gridCells.forEach(row => {
      row.forEach((gridCell: IGridCell) => {
        gridCell.setData();
        this.drawOffsetRect(gridCell);
      });
    });
    this.gridCells.forEach(row => {
      row.forEach((gridCell: IGridCell) => {
        this.drawRect(gridCell);
      });
    });
    this.gridCells.forEach(row => {
      row.forEach((gridCell: IGridCell) => {
        this.drawText(gridCell);
      });
    });
    this.gridCells.forEach(row => {
      row.forEach((gridCell: IGridCell) => {
        this.drawLines(gridCell);
      });
    });
    for (let i = 0; i < this.lines.length; i++) {
      this.drawLine(this.lines[i][0], this.lines[i][1], this.lines[i][2]);
    }

    // this.createGraphics();
    this.drawCanvas();

    if (options.download) {
      // polyfill
      if (!HTMLCanvasElement.prototype.toBlob) {
        Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
          value: function(callback: any, type: any, quality: any) {
            var canvas = this;
            setTimeout(function() {
              var binStr = atob(canvas.toDataURL(type, quality).split(',')[1]),
                len = binStr.length,
                arr = new Uint8Array(len);

              for (var i = 0; i < len; i++) {
                arr[i] = binStr.charCodeAt(i);
              }

              callback(new Blob([arr], { type: type || 'image/png' }));
            });
          }
        });
      }

      this.canvas.toBlob(function(blob) {
        let link: any = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = options.downloadName || `image.png`;
        link.click();
        setTimeout(() => {
          link = undefined;
        }, 300);
      });
    } else {
      return this.canvas.toDataURL();
    }
  }
  private resetElements() {
    this.bufferCanvas.width = this.width;
    this.bufferCanvas.height = this.height;
    this.canvas.width = this.width;
    this.canvas.height = this.height;
    // reset
    this.lines = [];
    this.offsetRectangles = [];
    this.rectangles = [];
    this.texts = [];
    this.hidden = [];
  }
  private clearCanvas() {
    this.bufferCanvasContext.save();
    this.bufferCanvasContext.clearRect(0, 0, this.width, this.height);
    this.bufferCanvasContext.scale(this.scale, this.scale);
  }
  private drawCanvas() {
    this.bufferCanvasContext.restore();
    this.canvasContext.clearRect(0, 0, this.width, this.height);
    this.canvasContext.drawImage(this.bufferCanvas, 0, 0);
  }
  private drawRect(cell: IGridCell) {
    // let t = this.translateCanvas(1);
    // let color = this.getBackgroundColor(cell);
    this.bufferCanvasContext.beginPath();
    this.bufferCanvasContext.fillStyle = `#${cell.content.style['background-color']}`;
    this.bufferCanvasContext.fillRect(cell.dataset.x, cell.dataset.y, cell.dataset.overflow, cell.dataset.height);
    // this.resetCanvas(t);
  }
  private drawOffsetRect(cell: IGridCell) {
    // let t = this.translateCanvas(1);
    this.bufferCanvasContext.beginPath();
    this.bufferCanvasContext.fillStyle = `#${cell.content.style['background-color']}`;
    this.bufferCanvasContext.fillRect(
      cell.dataset.x - 0.5,
      cell.dataset.y - 0.5,
      cell.dataset.width + 0.5,
      cell.dataset.height + 0.5
    );

    // this.resetCanvas(t);
  }
  private drawLines(cell: IGridCell) {
    // if (!coords) {
    //   console.log(cell, coords);
    // }

    const gridlineColor: string = 'd4d4d4';

    // // top line
    if (cell.content.style.tbs !== 'none' && cell.content.style.tbw !== 'none') {
      this.lines.push([
        { x: cell.dataset.x, y: cell.dataset.y },
        { x: cell.dataset.x + cell.dataset.overflow, y: cell.dataset.y },
        { width: this.borders.widths[cell.content.style.tbw!], color: cell.content.style.tbc }
      ]);
    }

    // right line
    if (cell.content.style.rbs !== 'none' && cell.content.style.rbw !== 'none') {
      this.lines.push([
        { x: cell.dataset.x + cell.dataset.overflow, y: cell.dataset.y },
        { x: cell.dataset.x + cell.dataset.overflow, y: cell.dataset.y + cell.dataset.height },
        { width: this.borders.widths[cell.content.style.rbw!], color: cell.content.style.rbc }
      ]);
    } else if (this.gridlines) {
      this.lines.push([
        { x: cell.dataset.x + cell.dataset.overflow, y: cell.dataset.y },
        { x: cell.dataset.x + cell.dataset.overflow, y: cell.dataset.y + cell.dataset.height },
        { width: 1, color: gridlineColor }
      ]);
    }

    // bottom line
    if (cell.content.style.bbs !== 'none' && cell.content.style.bbw !== 'none') {
      this.lines.push([
        { x: cell.dataset.x, y: cell.dataset.y + cell.dataset.height },
        { x: cell.dataset.x + cell.dataset.overflow, y: cell.dataset.y + cell.dataset.height },
        { width: this.borders.widths[cell.content.style.bbw!], color: cell.content.style.bbc }
      ]);
    } else if (this.gridlines) {
      this.lines.push([
        { x: cell.dataset.x, y: cell.dataset.y + cell.dataset.height },
        { x: cell.dataset.x + cell.dataset.overflow, y: cell.dataset.y + cell.dataset.height },
        { width: 1, color: gridlineColor }
      ]);
    }

    // left line
    if (cell.content.style.lbs !== 'none' && cell.content.style.lbw !== 'none') {
      this.lines.push([
        { x: cell.dataset.x, y: cell.dataset.y },
        { x: cell.dataset.x, y: cell.dataset.y + cell.dataset.height },
        { width: this.borders.widths[cell.content.style.lbw!], color: cell.content.style.lbc }
      ]);
    } else if (this.gridlines) {
      this.lines.push([
        { x: cell.dataset.x, y: cell.dataset.y },
        { x: cell.dataset.x, y: cell.dataset.y + cell.dataset.height },
        { width: 1, color: gridlineColor }
      ]);
    }
  }
  private drawLine(start: any, end: any, options: any) {
    let t = this.translateCanvas(options.width || 1);
    this.bufferCanvasContext.beginPath();
    this.bufferCanvasContext.lineWidth = options.width || 1;
    this.bufferCanvasContext.strokeStyle =
      options.color.indexOf('rgb') > -1 ? options.color : `#${(options.color || '000000').replace('#', '')}`;
    this.bufferCanvasContext.moveTo(start.x, start.y);
    this.bufferCanvasContext.lineTo(end.x, end.y);
    this.bufferCanvasContext.stroke();
    this.resetCanvas(t);
  }
  private drawText(cell: IGridCell) {
    // check access
    if (cell.permission === 'no') {
      return;
    }
    let v = this.cleanValue(cell.content.formatted_value || cell.content.value);
    if (!v.length) return;
    let color = cell.style && cell.style.color ? cell.style.color : `#${cell.content.style['color']}`;
    this.bufferCanvasContext.fillStyle = color;
    this.bufferCanvasContext.font = this.getFontString(cell); //  `${cell.content.style["font-style"]} ${cell.content.style["font-weight"]} ${cell.content.style["font-size"]} "${cell.content.style["font-family"].replace(/"/g, "").replace(/'/g, "")}", sans-serif`;
    this.bufferCanvasContext.textAlign = (cell.content.style['text-align'] || 'left') as CanvasTextAlign;
    //   .replace('justify', 'left')
    //   .replace('start', 'left');
    // cell.content.style['font-size'] = '9pt';
    // let m = canvasContext.measureText(v);
    let x = 0;
    let xPad = 3;
    let y = 0;
    let yPad = 5;
    switch (cell.content.style['text-align']) {
      case 'right':
        x = cell.dataset.x + cell.dataset.width - xPad;
        break;
      case 'middle':
      case 'center':
        x = cell.dataset.x + cell.dataset.width / 2;
        break;
      default:
        x = cell.dataset.x + xPad;
        break;
    }
    let wrapOffset = 0;
    let fontHeight = this.bufferCanvasContext.measureText('M').width;
    let lines: any = [];
    let wrap =
      (cell.content.style['text-wrap'] && cell.content.style['text-wrap'] === 'wrap') ||
      cell.content.style['word-wrap'] === 'break-word';
    if (wrap) {
      lines = this.findLines(v, fontHeight, cell.dataset.width - xPad * 2);
      wrapOffset = lines.offset;
    }
    switch (cell.content.style['vertical-align']) {
      case 'top':
        y = cell.dataset.y + fontHeight + yPad;
        break;
      case 'center':
      case 'middle':
        y = cell.dataset.y + cell.dataset.height / 2 - wrapOffset / 2 + fontHeight / 2;
        break;
      default:
        y = cell.dataset.y + cell.dataset.height - yPad - wrapOffset;
        break;
    }
    this.bufferCanvasContext.save();
    this.bufferCanvasContext.beginPath();
    this.bufferCanvasContext.rect(cell.dataset.x, cell.dataset.y, cell.dataset.overflow, cell.dataset.height);
    this.bufferCanvasContext.clip();
    if (wrap) {
      lines.lines.forEach((line: any) => {
        this.bufferCanvasContext.fillText(line.value, x, y + line.y);
        if (cell.content.link) {
          let yLine: number = Math.round(y + 2 + line.y);
          let tWidth: number = Math.round(this.bufferCanvasContext.measureText(line.value).width);
          let xLine: number = x;
          switch (cell.content.style['text-align']) {
            case 'right':
              xLine = x - tWidth;
              break;
            case 'middle':
            case 'center':
              xLine = x - tWidth / 2;
              break;
            default:
              break;
          }
          this.drawLine({ x: xLine, y: yLine }, { x: xLine + tWidth, y: yLine }, { width: 1, color: color });
        }
      });
    } else {
      if (
        cell.content.style['number-format'] &&
        cell.content.style['number-format'].indexOf('0.00') > -1 &&
        /(¥|€|£|\$)/.test(v)
      ) {
        let num = v.split(' ');
        this.bufferCanvasContext.fillText(num.length > 1 ? num[1] : v, x, y);
        if (num.length > 1) {
          this.bufferCanvasContext.textAlign = 'left';
          this.bufferCanvasContext.fillText(num[0], cell.dataset.x + xPad, y);
        }
      } else {
        this.bufferCanvasContext.fillText(v, x, y);
      }
      if (cell.content.link) {
        let yLine: number = Math.round(y + 2);
        let tWidth: number = Math.round(this.bufferCanvasContext.measureText(v).width);
        let xLine: number = x;
        switch (cell.content.style['text-align']) {
          case 'right':
            xLine = x - tWidth;
            break;
          case 'middle':
          case 'center':
            xLine = x - tWidth / 2;
            break;
          default:
            break;
        }
        this.drawLine({ x: xLine, y: yLine }, { x: xLine + tWidth, y: yLine }, { width: 1, color: color });
      }
    }
    this.bufferCanvasContext.restore(); // discard clipping region
  }
  private findLines(str: string, fontHeight: number, cellWidth: number) {
    let words = str.split(' ');
    let lines = [];

    let word: string[] = [];
    let lineY = 0;
    let lineOffset = fontHeight + 4; // leading?

    for (let w = 0; w < words.length; w++) {
      let wordWidth = this.bufferCanvasContext.measureText(words[w]).width + 2;

      if (wordWidth > cellWidth) {
        let letters = words[w].split('');
        let width = 0;
        wordWidth = 0;
        word = [];
        for (let i = 0; i < letters.length; i++) {
          width = this.bufferCanvasContext.measureText(word.join('')).width + 2;
          if (width < cellWidth) {
            word.push(letters[i]);
            wordWidth = width;
          } else {
            lines.push({
              value: word.join(''),
              width: wordWidth
            });
            word = [letters[i]];
          }
        }
        if (word.length) {
          lines.push({
            value: word.join(''),
            width: wordWidth
          });
        }
      } else {
        lines.push({
          value: words[w],
          width: wordWidth
        });
      }
    }

    let rows = [];
    let width = 0;
    let lineWords: string[] = [];
    for (let i = 0; i < lines.length; i++) {
      width += lines[i].width;
      if (width < cellWidth) {
        lineWords.push(lines[i].value);
      } else {
        rows.push({
          value: lineWords.join(' '),
          y: lineY
        });
        lineY += lineOffset;
        lineWords = [lines[i].value];
        width = lines[i].width;
      }
    }
    if (lineWords.length) {
      rows.push({
        value: lineWords.join(' '),
        y: lineY
      });
    }

    return {
      lines: rows,
      offset: fontHeight * (rows.length - 1) + (rows.length - 1) * 4
    };
  }
  private cleanValue(value: any) {
    return (value + '').trim().replace(/\s\s+/g, ' ');
  }
  private getFontString(cell: any): string {
    return `${cell.content.style['font-style']} ${cell.content.style['font-weight']} ${cell.content.style['font-size']} "${cell.content.style['font-family']}", sans-serif`;
  }
  private translateCanvas(w: number) {
    let translate = (w % 2) / 2;
    this.bufferCanvasContext.translate(translate, translate);
    return translate;
    // canvasContext.translate(-iTranslate, -iTranslate);
  }
  private resetCanvas(translate: number) {
    this.bufferCanvasContext.translate(-translate, -translate);
  }
}

export default GridCanvas;
