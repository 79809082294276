export interface IClassy {
    hasClass(ele: HTMLElement, cls: string): boolean;
    addClass(ele: HTMLElement, cls: string): any;
    removeClass(ele: HTMLElement, cls: string): any;
}

class Classy implements IClassy {

    constructor() {
        return;
    }

    public hasClass(ele: HTMLElement, cls: string): boolean {
        if (!ele || typeof ele.className === "undefined") { return false; }
        return (ele.className.match(new RegExp(`(\\s|^)${cls}(\\s|$)`))) ? true : false;
    }

    /**
     * Adds class to an element
     *
     * @param ele
     * @param cls
     * @returns {boolean}
     */
    public addClass(ele: HTMLElement, cls: string): any {
        if (!ele) { return false; }
        // if (!this.hasClass(ele, cls)) { ele.className += " " + cls; }
        ele.classList.add(cls);
    }

    /**
     * Removes class from element
     *
     * @param ele
     * @param cls
     * @returns {boolean}
     */
    public removeClass(ele: HTMLElement, cls: string): any {
        if (!ele) { return false; }
        // if (this.hasClass(ele, cls)) {
        //     let reg: any = new RegExp(`(\\s|^)${cls}(\\s|$)`);
        //     ele.className = ele.className.replace(reg, " ");
        // }
        ele.classList.remove(cls);
    }

}
export default Classy;